import React from "react";
import { addCommas, interpolateColor } from "../../utils/Utils";
import { Col, Row, Space, Tooltip } from "antd";

/**
 * Sales Component
 */
export default function Sales(props) {
    const {
        filters = {},
        list = true,
        scale = false,
        sales = [],
        salesrtrn = [],
        record = {},
        getSet = () => { },
        setDrilldown = () => { }
    } = props;

    const { show_which = "dollars" } = filters;

    const rel_scale = (scale) ? 1 : getSet(record, "rel_scale");

    // Generate color gradients for sales and sales returns data
    const sales_colours = interpolateColor("#b8d4e0", "#009ee1", sales.length);
    const salesrtrn_colours = interpolateColor("#00f1ff", "#a0faff", salesrtrn.length);


    function pol(number) {
        return number >= 0 ? `+${number}` : `${number}`;
    }

    /**
     * Generates tooltip content for a given item.
     */
    const drawToolTip = (item, per = "") => {
        const dollars = getSet(record, item.key, false, "dollars")
        const copies = getSet(record, item.key, false, "unit_copies")
        const isbns = getSet(record, item.key, false, "unique_isbns")

        let dollars_per;
        let dollars_past;
        let dollars_this; 

        if (per) {
            dollars_this = getSet(record, per, false, "dollars");
            dollars_past = getSet(record, per, true, "dollars");
            dollars_per = (dollars / dollars_past) * 100;
        }

        let title_label = item.label;
        let label_1 = "retail";
        let val_1 = "$" + addCommas(dollars);

        let label_2 = "copies";
        let val_2 = addCommas(copies);

        let label_3 = "ISBNs";
        let val_3 = addCommas(isbns);

        switch (item.key) {

            case "sales_diff":
                return (
                    <div>
                        <div>{title_label}</div>
                        {/* <div>This: {dollars_this} - Past: {dollars_past}</div> */}
                        <div>  {pol(dollars_per.toFixed(1))}% (${addCommas(dollars)})</div>
                        <Space>
                            <div>
                                <div>{addCommas(pol(copies))}</div>
                                <div>{addCommas(pol(isbns))}</div>
                            </div>
                            <div>
                                <div>copies</div>
                                <div>ISBNs</div>
                            </div>
                        </Space>
                    </div>
                )

                break;

        }

        return (
            <div>
                <div>{title_label}</div>
                <Space>
                    <div>
                        <div>{val_1}</div>
                        <div>{val_2}</div>
                        <div>{val_3}</div>
                    </div>
                    <div>
                        <div>{label_1}</div>
                        <div>{label_2}</div>
                        <div>{label_3}</div>
                    </div>
                </Space>
            </div>
        );
    };

    /**
     * Renders a segment with optional nested segment inside.
     */
    const drawSegment = (item, _color = "#ff0000", inside = false, _rtn_color) => {
        const scale = getSet(record, item.key + "_w");
        const width_percent = (scale * rel_scale) * 100;
        const multiplier = 100 / width_percent;

        const iscale = inside ? getSet(record, inside.key + "_w") : 0;
        const inside_percent = inside ? ((iscale * rel_scale) * 100) * multiplier : 0;

        return (
            <div
                className="lighten"
                style={{
                    width: list ? `${width_percent}%` : "10px",
                    height: list ? "10px" : `${width_percent}%`,
                    backgroundColor: _color,
                    position: "relative"
                }}
            >
                {inside && (
                    <Tooltip title={drawToolTip(inside)}>
                        <div
                            onClick={() => setDrilldown(inside.key, record?.id)}
                            className="txtptrn"
                            style={{
                                width: list ? `${inside_percent}%` : "10px",
                                height: list ? "10px" : `${inside_percent}%`,
                                backgroundColor: _rtn_color,
                                position: "absolute",
                                right: "0px",
                                zIndex: "10"
                            }}
                        />
                    </Tooltip>
                )}
                <Tooltip title={drawToolTip(item)}>
                    <div
                        onClick={() => setDrilldown(item.key, record?.id)}
                        style={{
                            width: list ? "100%" : "10px",
                            height: list ? "10px" : "100%"
                        }}
                    />
                </Tooltip>
            </div>
        );
    };

    /**
     * Renders all sales segments.
     */
    const drawSegments = () => {
        return sales.map((item, index) => {
            return drawSegment(item, "#" + item.color);
            // return drawSegment(item, "#" + item.color, salesrtrn[index], "#" + salesrtrn[index].color);
        });
    };

    /**
     * Renders the sales difference indicator.
     */
    const drawSalesDiff = (show = true) => {
        const is_positive = getSet(record, "sales_diff") > 0;
        const sales_per = getSet(record, "sales_total_w");
        const scale = getSet(record, "sales_diff_w");
        const width_percent = (scale * rel_scale) * 100;

        const size = list ? 998 * (width_percent / 100) : 400 * (width_percent / 100);

        if (is_positive) {
            if (!show) return null;

            return (
                <div
                    style={{
                        position: "relative",
                        width: "0px",
                        height: "0px",
                        top: list ? "-5px" : "0px",
                        right: list ? `${size}px` : "5px",
                        zIndex: "11"
                    }}
                >
                    <Tooltip title={drawToolTip({ key: "sales_diff", label: "Sales YOY change" }, "sandr_total")}>
                        <div
                            onClick={() => setDrilldown("sales_diff", record?.id)}
                            className="lighten"
                            style={{
                                width: list ? `${size}px` : "5px",
                                height: list ? "5px" : `${size}px`,
                                backgroundColor: "#0f0"
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (!show) {
            return (
                <div
                    style={{
                        opacity: "0",
                        width: list ? `${width_percent}%` : "6px",
                        height: list ? "6px" : `${width_percent}%`,
                        pointerEvents: "none"
                    }}
                />
            );
        }

        return (
            <Tooltip title={drawToolTip({ key: "sales_diff", label: "Sales YOY change" }, "sandr_total")}>
                <div
                    className="lighten"
                    onClick={() => setDrilldown("sales_diff", record?.id)}
                    style={{
                        backgroundColor: "#d03333",
                        width: list ? `${width_percent}%` : "5px",
                        height: list ? "5px" : `${width_percent}%`,
                        marginBottom: list ? "-0px" : "0",
                        marginRight: list ? "0" : "-0px"
                    }}
                />
            </Tooltip>
        );
    };


    const drawProfit2 = () => {

        if (show_which !== "dollars") {
            return (<></>)
        }

        const scale = record.profit_w;
        const width_percent = (scale * rel_scale) * 100;
        const size = list ? 998 * (width_percent / 100) : 400 * (width_percent / 100);

        return (
            <Tooltip title={<><div>Profit: ${addCommas(record.profit)}</div><div>Margin: {addCommas(record.profit_margin) + "%"}</div></>}>
                <div
                     onClick={() => setDrilldown("sales_total", record?.id)}
                    // className="lighten"
                    style={{
                        position: "absolute",
                        bottom: (!list) ? "0px" : "inherit",
                        zIndex: "3",
                        top: (list) ? "0px" : "inherit",
                        left: (!list) ? "0px" : "inherit",
                        width: list ? `${size}px` : "5px",
                        height: list ? "5px" : `${size}px`,
                        backgroundColor: "#000088"
                    }}
                />
            </Tooltip>
        )

    }

    const drawProfit = () => {

        if (show_which !== "dollars") {
            return (<></>)
        }

        const scale = record.profit_w;
        const scale2 = getSet(record, "sales_total_w");
        const width_percent = (scale * rel_scale) * 100;
        const width_percent2 = (scale2 * rel_scale) * 100;

        const size = list ? 998 * (width_percent / 100) : 400 * (width_percent / 100);

        const offset = list ? 997 * (width_percent2 / 100) : 400 * (width_percent2 / 100);


        return (
            <div
                style={{
                    position: "relative",
                    width: "0px",
                    height: "0px",
                    top: list ? "-5px" : `0px`,
                    right: list ? `${size}px` : "5px",
                    bottom: "0px",
                    zIndex: "10"
                }}
            >
                <Tooltip title={<><div>Profit</div><div>{addCommas(record.profit)}</div></>}>
                    <div
                        // onClick={() => setDrilldown("profit", record?.id)}
                        // className="lighten"
                        style={{
                            width: list ? `${size}px` : "5px",
                            height: list ? "5px" : `${size}px`,
                            backgroundColor: "#000088"
                        }}
                    />
                </Tooltip>
            </div>
        )
    }

    const drawLabel = () => {

        let ret = "";
        switch (show_which) {
            case "dollars":
                ret = <>${addCommas(getSet(record, "sandr_total", false, "dollars"))} <small>retail</small></>
                break;
            case "unit_copies":
                ret = <>{addCommas(getSet(record, "sandr_total", false, "unit_copies"))} <small>units</small></>
                break;
            case "unique_isbns":
                ret = <>{addCommas(getSet(record, "sandr_total", false, "unique_isbns"))} <small>isbns</small></>
                break;
        }

        return ret;

    }

    return (
        <div>

            <Row>
                {(list &&
                    <Col flex={"200px"}>
                        <div style={{ "padding": "0px 0px" }}>
                            <Space>
                                <div style={{ "width": "70px" }}>Sales</div>
                                <div style={{ "width": "100px", "textAlign": "right" }}>{drawLabel()}</div>
                            </Space>
                        </div>
                    </Col>
                )}

                <Col flex={"auto"}>
                    <div>
                        {drawProfit2()}
                        <div style={{ display: list ? "block" : "flex" }}>
                            {/* Overall sales and returns bar */}
                            <div
                                style={{
                                    height: list ? "10px" : "400px",
                                    display: "flex",
                                    flexDirection: list ? "row" : "column-reverse",
                                    alignItems: "flex-end"
                                }}
                            >

                                {drawSegment({ key: "sandr_total", label: "Sales" }, "#4a7682", { key: "salesrtrn_total", label: "Customer Returns" }, "#007682")}
                                {/* {drawProfit()} */}

                                {drawSalesDiff()}
                            </div>

                            {/* Individual sales and returns segments */}

                            <div
                                style={{
                                    height: list ? "10px" : "400px",
                                    display: "flex",
                                    flexDirection: list ? "row" : "column-reverse"
                                }}
                            >
                                {drawSegments()}
                                {drawSalesDiff(false)}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    );
}
