import React from "react";
import { Typography, Divider, Row, Col, Space, Form, Input } from "antd";
export default function SeoSection(props) {


    const { ws } = props;

    return (
        <>
            <div id="seo" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>SEO</Typography.Title>
                <Divider />
                {/* Hours */}
                <div id="ws_metadesc"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5} >Meta tags</Typography.Title>
                        <Typography.Paragraph type="secondary" >Meta tags provide web browsers and search engines with a simple description of your site and important keywords that people might use to find you. It can take a while for service providers and search engines to apply your changes.</Typography.Paragraph>
                        <Typography.Paragraph><strong>Meta Tag Description:</strong></Typography.Paragraph>
                        <Form.Item name="global:ws_metadesc" initialValue={ws["global:ws_metadesc"]} noStyle >
                            <Input maxLength={255} style={{ "maxWidth": "500px" }}></Input>
                        </Form.Item>
                        <Typography.Paragraph type="secondary">Example: Busytown's local independent bookstore since 1942 (limit to 255 characters)</Typography.Paragraph>
                        <Typography.Paragraph><strong>Meta Tag Keywords:</strong></Typography.Paragraph>
                        <Form.Item name="global:ws_metakeyw" initialValue={ws["global:ws_metakeyw"]} noStyle >
                            <Input maxLength={200} style={{ "maxWidth": "500px" }}></Input>
                        </Form.Item>
                        <Typography.Paragraph type="secondary">Example: bookstore, books, busytown, independent, Canadian fiction (limit to 200 characters and separate keywords with a comma and space)</Typography.Paragraph>
                    </div>
                </Space>

                <Divider dashed />
                {/* Third party codes */}
                <div id="ws_google_analytics_ua"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>

                    <Typography.Title level={5} >Third party codes</Typography.Title>
                        <Typography.Text><strong>Meta Pixel: <small><em>AKA. Facebook Pixel</em></small></strong></Typography.Text>
                        <div style={{ "width": "400px" }} className="shim"></div>
                        <Form.Item name="global:ws_facebook_pixel_id" initialValue={ws["global:ws_facebook_pixel_id"]} noStyle >
                            <Input minLength={15} maxLength={20} style={{ "maxWidth": "200px" }} ></Input>
                        </Form.Item>

                        <Divider dashed />
                        <Typography.Title level={5} >Third party codes</Typography.Title>
                        <Typography.Text><strong>Google Analytics code: </strong></Typography.Text>
                        <div style={{ "width": "400px" }} className="shim"></div>
                        <Form.Item name="global:ws_google_analytics_ua" initialValue={ws["global:ws_google_analytics_ua"]} noStyle >
                            <Input maxLength={20} style={{ "maxWidth": "200px" }} ></Input>
                        </Form.Item>
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                        <Typography.Text><strong>Google Site Verification code: </strong></Typography.Text>
                        <Typography.Text></Typography.Text>
                        <div className="shim"></div>
                        <Form.Item rules={[{ pattern: /^[a-zA-Z0-9_-]*$/, message: 'Use verification code only' }]} name="global:ws_meta_google" initialValue={ws["global:ws_meta_google"]} >
                            <Input maxLength={44}></Input>
                        </Form.Item>
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div>

                    </div>
                </Space>
            </div>
        </>
    )
}