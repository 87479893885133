import { Row, Col, Tooltip, Space, Modal } from "antd";
import React from "react";
import { addCommas } from "../../utils/Utils";
import Html from "../../utils/Html";
import moment from "moment";
import { QuestionCircleOutlined } from '@ant-design/icons';

export default function AnalyticsDetails(props) {

    const { filters = {}, data = {}, single = false, list = false } = props;


    const getSet = (_key, _prev = false) => {
        let _set = "price";
        switch (filters.show_which) {
            case "dollars":
                _set = "dollars"
                break;
            case "unit_copies":
                _set = "units"
                break;
            case "unique_isbns":
                _set = "titles"
                break;
        }
        if (_prev) {
            return data.prev[_set][_key];
        }
        return data[_set][_key];
    }

    // Sales Data
    let sales = {
        label: "Sales",
        total: getSet("sandr_total", false),
        prev_total: getSet("sandr_total", true),
        prev_per: (filters.show_which === "dollars") ? (365 / data.this_slice_days * data.prev.profit / getSet("onhand_days", true)).toFixed(1) : "<small>n/a</small>",
        prev_grp: data.prev.sandr_percentage
    }

    sales.per = (filters.show_which === "dollars") ? (365 / data.this_slice_days * data.profit / getSet("onhand_days", false)).toFixed(1) : "<small>n/a</small>";
    sales.grp_per = data.sandr_percentage;
    sales.diff_total = sales.total - sales.prev_total
    sales.diff_per = Math.round((sales.total - sales.prev_total) / sales.prev_total * 100);

    // Sales Styles
    sales.total = `<span style="color:#0089ae;"><strong>` + addCommas(sales.total) + `</strong></span>`;
    let _color = (sales.diff_per > 0) ? "inherit" : "#d13232";
    sales.diff_per = `<span style="color:` + _color + `;">` + addCommas(sales.diff_per) + `%</span>`;
    sales.prev_total = `<span style="color:#0089ae;"><strong>` + addCommas(sales.prev_total) + `</strong></span>`;
    _color = (sales.diff_total > 0) ? "inherit" : "#d13232";
    sales.diff_total = `<span style="color:` + _color + `;">` + addCommas(sales.diff_total) + `</span>`;
    sales.per = `<span>` + addCommas(sales.per) + `<small> profit index</small></span>`;
    sales.prev_per = `<span>` + addCommas(sales.prev_per) + `<small> profit index</small></span>`;


    // Profit Data
    let profit = {
        label: "Profit",
        total: data.profit,
        prev_total: data.prev.profit,
        prev_per: data.prev.profit_margin,
        prev_grp: (filters.show_which === "dollars") ? data.prev.profit_percentage : `<small>n/a</small>`,
    }

    profit.per = data.profit_margin
    profit.grp_per = (filters.show_which === "dollars") ? data.profit_percentage : `<small>n/a</small>`;
    profit.diff_total = profit.total - profit.prev_total
    profit.diff_per = Math.round((profit.total - profit.prev_total) / profit.prev_total * 100);

    // Profit Styles
    profit.total = `<span style="color:green;"><strong>` + addCommas(profit.total) + `</strong></span>`;
    _color = (profit.diff_per > 0) ? "inherit" : "#d13232";
    profit.diff_per = `<span style="color:` + _color + `;">` + addCommas(profit.diff_per) + `%</span>`;
    profit.prev_total = addCommas(profit.prev_total);
    _color = (profit.diff_total > 0) ? "inherit" : "#d13232";
    profit.diff_total = `<span style="color:` + _color + `;">` + addCommas(profit.diff_total) + `</span>`;
    _color = "green";
    profit.per = `<span><span style="color:` + _color + `;"><strong>` + addCommas(profit.per) + `</span></strong><small> % pm</small></span>`;
    profit.prev_per = `<span>` + addCommas(profit.prev_per) + `<small> % pm</small></span>`;



    //Cur onhand

    let current = {
        label: "Cur onhand",
        total: getSet("onhand_total", false),
        prev_total: getSet("onhand_total", true),
        prev_per: data.prev.units.avg_ohage,
        prev_grp: data.prev.onhand_percentage
    }

    current.per = data.units.avg_ohage;
    current.grp_per = data.onhand_percentage;
    current.diff_total = current.total - current.prev_total
    current.diff_per = Math.round((current.total - current.prev_total) / current.prev_total * 100);

    // Cur Styles
    current.total = addCommas(current.total)
    _color = (current.diff_per > 0) ? "inherit" : "#d13232";
    current.diff_per = `<span style="color:` + _color + `;">` + addCommas(current.diff_per) + `%</span>`;
    current.prev_total = addCommas(current.prev_total);
    _color = (current.diff_total > 0) ? "inherit" : "#d13232";
    current.diff_total = `<span style="color:` + _color + `;">` + addCommas(current.diff_total) + `</span>`;
    _color = "green";
    current.per = `<span>` + addCommas(current.per) + `<small> days old</small></span>`;
    current.prev_per = `<span>` + addCommas(current.prev_per) + `<small> days old</small></span>`;



    //Avg onhand

    let average = {
        label: "Avg onhand",
        total: getSet("onhand_days", false),
        prev_total: getSet("onhand_days", true),
        prev_per: getSet("turns", true),
        prev_grp: (filters.show_which !== "unique_isbns") ? data.prev.onhand_days_percentage : `<small>n/a</small>`,
    }

    average.per = getSet("turns", false)
    average.grp_per = (filters.show_which !== "unique_isbns") ? data.onhand_days_percentage : `<small>n/a</small>`;
    average.diff_total = average.total - average.prev_total
    average.diff_per = Math.round((average.total - average.prev_total) / average.prev_total * 100);

    // Avg Styles
    average.total = (average.total) ? addCommas(average.total) : "<small>n/a</small>"
    _color = (average.diff_per > 0) ? "inherit" : "#d13232";
    average.diff_per = (average.diff_per) ? `<span style="color:` + _color + `;">` + addCommas(average.diff_per) + `%</span>` : "<small>n/a</small>";
    average.prev_total = addCommas(average.prev_total);
    _color = (average.diff_total > 0) ? "inherit" : "#d13232";
    average.diff_total = (average.diff_total) ? `<span style="color:` + _color + `;">` + addCommas(average.diff_total) + `</span>` : "<small>n/a</small>"
    _color = "green";
    average.per = (average.per) ? `<span>` + average.per + `<small> turns</small></span>` : "<small>n/a</small>";
    average.prev_per = (average.prev_per) ? `<span>` + average.prev_per + `<small> turns</small></span>` : "<small>n/a</small>";


    //Received
    let received = {
        label: "Received",
        total: getSet("rcvd", false),
        prev_total: getSet("rcvd", true),
        prev_per: "", // Does not seem to have a value
        prev_grp: "", // Does not seem to have a value
    }

    received.per = ""; // Does not seem to have a value

    received.grp_per = ""; // Does not seem to have a value
    received.diff_total = received.total - received.prev_total
    received.diff_per = Math.round((received.total - received.prev_total) / received.prev_total * 100);

    // Received Styles
    received.total = (received.total) ? addCommas(received.total) : "<small>n/a</small>"
    _color = (received.diff_per > 0) ? "inherit" : "#d13232";
    received.diff_per = `<span style="color:` + _color + `;">` + addCommas(received.diff_per) + `%</span>`;

    received.prev_total = addCommas(received.prev_total);
    _color = (average.diff_total > 0) ? "inherit" : "#d13232";
    received.diff_total = (received.diff_total) ? `<span style="color:` + _color + `;">` + addCommas(received.diff_total) + `</span>` : "<small>n/a</small>"


    //Returns
    let returns = {
        label: "Returns",
        total: -getSet("rtrn", false),
        prev_total: -getSet("rtrn", true),
        prev_per: Math.round(getSet("rtrn", true) / getSet("rcvd", true) * 100),
        prev_grp: "", // Does not seem to have a value
    }

    returns.per = getSet("rtrn_percent", false); //Math.round(getSet("rcvd", false) / getSet("rtrn", false));

    returns.grp_per = ""; // Does not seem to have a value
    returns.diff_total = returns.total - returns.prev_total
    returns.diff_per = -Math.round((returns.total - returns.prev_total) / returns.prev_total * 100);

    // Received Styles

    returns.total = (returns.total) ? addCommas(returns.total) : "<small>n/a</small>"
    _color = (returns.diff_per > 0) ? "inherit" : "#d13232";
    returns.diff_per = `<span style="color:` + _color + `;">` + addCommas(returns.diff_per) + `%</span>`;

    returns.per = `<span>` + returns.per + `<small>% of rcv</small></span>`;
    returns.prev_per = `<span>` + returns.prev_per + `<small>% of rcv</small></span>`;

    returns.prev_total = addCommas(returns.prev_total);
    _color = (average.diff_total > 0) ? "inherit" : "#d13232";
    returns.diff_total = (returns.diff_total) ? `<span style="color:` + _color + `;">` + addCommas(returns.diff_total) + `</span>` : "<small>n/a</small>"




    const drawRow = (_obj, _tooltip = "") => {



        return (
            <Row gutter={15} className="bc c">
                <Col style={{ "textAlign": "right" }} flex={"100px"}>
                    <small><em>{_obj.label}</em></small>
                </Col>

                <Col className="bc" style={{ "borderLeft": "1px solid" }} flex={"100px"}>
                    <Html html={_obj.total} />
                </Col>
                <Col flex={"100px"}>
                    <Html html={_obj.diff_per} />
                </Col>
                <Col flex={"100px"}>
                    <Html html={_obj.diff_total} />
                </Col>
                <Col flex={"130px"}>
                    <Space><Html html={_obj.per} />{((_tooltip) && <small><QuestionCircleOutlined onClick={() => drawTipModal(_tooltip)} style={{ "opacity": "0.6", "cursor": "pointer" }} /></small>)}</Space>
                </Col>
                <Col flex={"100px"}>
                    <Html html={_obj.grp_per} />
                </Col>

                <Col className="bc" style={{ "borderLeft": "1px solid" }} flex={"100px"}>
                    <Html html={_obj.prev_total} />
                </Col>
                <Col flex={"130px"}>
                    <Space><Html html={_obj.prev_per} />{((_tooltip) && <small><QuestionCircleOutlined onClick={() => drawTipModal(_tooltip)} style={{ "opacity": "0.6", "cursor": "pointer" }} /></small>)}</Space>
                </Col>
                <Col flex={"133px"}>
                    <Html html={_obj.prev_grp} />
                </Col>
            </Row>

        )

    }



    const drawTipModal = (_key) => {

        switch (_key) {
            case "sales":
                Modal.info({
                    icon: false,
                    content: <>
                        <p>Profit for the period annualized (profit on sales / #days in the period x 365 days) then divided by the average onhand (at cost). The higher the index, the better. <br /><br /> Similar to Turns, except it factors in profitability. Low margin inventory needs to sell faster (higher turns) whereas high margin inventory can have lower sales (lower turns) but be equally valuable.</p>
                    </>
                })
                break;

            case "current_onhand":
                Modal.info({
                    icon: false,
                    content: <>
                        <p>How many days on average the current inventory has been sitting on the shelf unsold. <br /><br />Changing the period will not affect this average because we are looking at the current, as-of-this-moment inventory onhand.</p>
                    </>
                })
                break;

            case "avg_onhand":
                Modal.info({
                    icon: false,
                    width: "800px",
                    content: <>
                        <p>The average turn rate in the book industry is around 3.0. This means that if you always stocked exactly 100 books in your store over a year, and at the end of the year you had sold 300 books, you take 300 units divided by the 100 unit daily average to arrive at 3.0 turns. Put another way, on average it took about 4 months to sell each book (12 months divided by 3.0 turns.) If you are able to cut your selection back to 50 books on average, but still managed to sell 300 books, 300 units divided by 50 units = 6.0 turns (or two months to sell each book.) In essence, higher turns require less inventory and therefore less cash outlay or borrowing.<br /><br />
                            But wait, you say, bread at the grocery store sells out nearly every day, resulting in 365 turns! And we have to survive on 3 turns? The answer is that bread is sold for a tiny profit compared to books. The higher profit on books allows us to finance the costs of carrying the books for a longer period and also provide the space to display them.<br /><br />
                            High turns are great in most cases, but they can also indicate that you might be able to increase your selection (to provide more variety or never be sold out) and generate still more sales. Similarly, low turns means you should really have a closer look at what is sitting on your shelf that does not really need to be there to generate sales. For example, you might buy 12 of something that sells one per month (1 turn). It might be better to have 3 in stock at any given time but aim never to be out (4 turns). If you took this to the extreme, you could try to stock just one copy and try to get a replacement copy as soon as it sold (12 turns). The problem with carrying only one copy is that you will never know if that customer wanted two copies or if there was another customer wanting one after the first was sold out. Those high turns look great (and keep your bank loan small) but you are also losing potential sales.<br /><br />
                            Finally, it is important to know that product that has a high margin of profit (e.g. cost is $2 and sold at $10) can turn at a lower rate than items that have low margins (e.g. cost is $8 and sold at $10). You should not strive to have each category of product in your store (e.g. books, magazines and high margin giftware) turn at the same rate. Using the Profit Index factors in the margin and is probably a better rate to keep an eye on, and it can be used for an apples to apples comparison between different product types.</p>
                    </>
                })
                break;
        }

    }

    const relDiff = (a, b) => {
        return 100 * Math.abs((a - b) / ((a + b) / 2));
    }

    const getUnit = () => {

        let ret = "";
        switch (filters.show_which) {
            case "dollars":
                ret = "dollars"
                break;
            case "unit_copies":
                ret = "units"
                break;
            case "unique_isbns":
                ret = "titles"
                break;
        }

        return ret;

    }

 
    return (
        <>
        {((single && !list) && <div style={{"height" : "23px"}}></div>)}
        
        <div className="float-flex">
            {((list) && <div style={{"width" : "0px"}}></div>)}
            {((!list && !single) && <div style={{"width" : "30px"}}></div>)}
            <div style={{"width" : (single) ? "1080px" : "100%"}}>
                <Row gutter={15} className="bc bcg3">
                    <Col flex={"100px"}>
                        &nbsp;
                    </Col>
                    <Col className="bc c" style={{ "borderLeft": "1px solid" }} flex={"530px"}>
                        <div style={{ "fontSize": "13px", "fontWeight": "600" }}><small><em>This Period</em></small></div>
                    </Col>

                    <Col className="bc c" style={{ "borderLeft": "1px solid", "marginRight": "10px" }} flex={"363px"}>
                        <div style={{ "fontSize": "13px", "fontWeight": "600" }}><small><em>Previous Year <span style={{ "fontWeight": "normal" }}> &nbsp; {moment(data.prev.slice_datelow * 1000).format("ddd MMM D, YYYY")} to {moment(data.prev.slice_datehigh * 1000).format("MMM D, YYYY")}</span></em></small></div>
                    </Col>
                </Row>

                <Row gutter={15} className="bc bcg3" style={{ "borderBottom": '1px solid' }}>
                    <Col flex={"100px"} className="bcg2">
                        &nbsp;
                    </Col>

                    <Col className="bc c" style={{ "borderLeft": "1px solid" }} flex={"100px"}>
                        <small><em>in {getUnit()}</em></small>
                    </Col>
                    <Col className="c" flex={"100px"}>
                        <small><em>% chg</em></small>
                    </Col>
                    <Col className="" flex={"100px"}>
                        <small><em>$ chg</em></small>
                    </Col>
                    <Col className="c" flex={"130px"}>
                        <small><em>Performance</em></small>
                    </Col>
                    <Col className="c" flex={"100px"}>
                        <small><em>% of grp</em></small>
                    </Col>

                    <Col className="bc c" style={{ "borderLeft": "1px solid" }} flex={"100px"}>
                        <small><em>in {getUnit()}</em></small>
                    </Col>
                    <Col className="c" flex={"130px"}>
                        <small><em>Performance</em></small>
                    </Col>
                    <Col className="c" flex={"133px"}>
                        <small><em>% of grp</em></small>
                    </Col>
                </Row>

                {drawRow(sales, "sales")}
                {drawRow(profit)}
                {drawRow(current, "current_onhand")}
                {drawRow(average, "avg_onhand")}
                <div className="shim" /><div className="shim" />
                {drawRow(received)}
                {drawRow(returns)}

                {/* {drawRow("Sales", 
            current.sales_total, 
            relDiff(previous.sales_total,current.sales_total).toFixed(2), 
            (current.sales_total - previous.sales_total))}
            {drawRow("Profit")}
            {drawRow("Cur onhand")}
            {drawRow("Avg onhand")}
            {drawRow(<>&nbsp;</>)}
            {drawRow("Received")}
            {drawRow("Returns")} */}



            </div>
        </div>
        </>
    )
}