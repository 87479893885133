import React, { useRef, useState, useEffect } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";

const DragWrapper = ({ children, enableBackgroundDrag = true, includes, results, list = true }) => {
  const containerRef = useRef(null);
  const childRef = useRef(null);
  const [maxDrag, setMaxDrag] = useState(0);
  const [showScroll, setShowScroll] = useState(false);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const x = useMotionValue(0); // Tracks the content position
  const scrollbarX = useMotionValue(0); // Tracks the scrollbar position
  const [isDraggingScrollbar, setIsDraggingScrollbar] = useState(false);

  useEffect(() => {
    const updateDimensions = () => {

      if (containerRef.current && childRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const childWidth = childRef.current.scrollWidth;

        if (childWidth > containerWidth) {
          const maxDragValue = containerWidth - childWidth;
          setMaxDrag(maxDragValue);
          setScrollbarWidth((containerWidth / childWidth) * containerWidth);
          setShowScroll(true);

          // Set initial x and scrollbarX to start at the far left
          x.set(0);
          scrollbarX.set(0);
        } else {
          setMaxDrag(0);
          setShowScroll(false);
        }
      }
      x.set(0);
      scrollbarX.set(0);
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [x, scrollbarX, includes, results, list]);

  useEffect(() => {
    if (!isDraggingScrollbar) {
      const unsubscribe = x.onChange((latestX) => {
        if (containerRef.current && childRef.current) {
          const containerWidth = containerRef.current.offsetWidth;
          const childWidth = childRef.current.scrollWidth;
          const normalizedX =
            (latestX / (childWidth - containerWidth)) * (containerWidth - scrollbarWidth);
          scrollbarX.set(-normalizedX);
        }
      });

      return () => unsubscribe();
    }
  }, [x, scrollbarX, isDraggingScrollbar, scrollbarWidth]);


  const [scrollbarDragOffset, setScrollbarDragOffset] = useState(0);

  const handleScrollBarDragStart = (event, info) => {
    if (containerRef.current) {
      // Calculate the initial offset within the scrollbar
      const scrollbarRect = containerRef.current.getBoundingClientRect();
      const initialOffset = info.point.x - scrollbarRect.left - scrollbarX.get();

      setScrollbarDragOffset(initialOffset);
      setIsDraggingScrollbar(true);
    }
  };

  const handleScrollBarDrag = (event, info) => {
    if (containerRef.current && childRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const childWidth = childRef.current.scrollWidth;

      // Account for the initial offset
      const relativeDragX =
        info.point.x - containerRef.current.getBoundingClientRect().left - scrollbarDragOffset;

      // Clamp the position
      const normalizedX = Math.max(
        0,
        Math.min(relativeDragX, containerWidth - scrollbarWidth)
      );

      // Map normalized scrollbar position to content drag position
      const contentX = -(normalizedX / (containerWidth - scrollbarWidth)) * (childWidth - containerWidth);

      x.set(contentX); // Update content position
    }
  };

  const handleScrollBarDragEnd = () => {
    setIsDraggingScrollbar(false);
  };

  if (list) {
    return (<> {children}</>)
  }

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <motion.div
        ref={childRef}
        
        drag={enableBackgroundDrag ? "x" : false} // Enable or disable background drag
        dragConstraints={{ left: maxDrag, right: 0 }}
        dragElastic={0}
        dragMomentum={false} // Disable throwing
        style={{
          display: "inline-block",
          cursor: enableBackgroundDrag ? "default" : "default",
          marginTop : "10px",
          x, // Bind x value for dragging
        }}
      >
        {children}
        <div className="shim" style={{ "height": "8px" }} />
      </motion.div>

      {showScroll && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "8px",
            width: "100%",

            backgroundColor: "#e0e0e0",
          }}
        >
          <motion.div
            drag="x"
            dragConstraints={{
              left: 0,
              right: containerRef.current
                ? containerRef.current.offsetWidth - scrollbarWidth
                : 0,
            }}
            dragElastic={0}
            dragMomentum={false}
            style={{
              x: scrollbarX,
              height: "100%",
              backgroundColor: "#007bff",
              width: `${scrollbarWidth}px`,
              cursor: "pointer",
            }}
            onDragStart={handleScrollBarDragStart}
            onDrag={handleScrollBarDrag}
            onDragEnd={handleScrollBarDragEnd}
          />
        </div>
      )}
    </div>
  );
};

export default DragWrapper;
