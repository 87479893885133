import React from "react";
import { addCommas, interpolateColor } from "../../utils/Utils";
import { Col, Row, Space, Tooltip } from "antd";

/**
 * Received Component
 */
export default function Received(props) {
    const {
        filters = {},
        list = true,
        scale = false,
        record = {},
        getSet = () => { },
        setDrilldown = () => { }
    } = props;

    const { show_which = "dollars" } = filters;

    const rel_scale = (scale) ? 1 : getSet(record, "rel_scale");

    /**
     * Renders the return.
     */
    const drawReturn = (show = true) => {
        const scale = getSet(record, "rtrn_w");
        const width_percent = (scale * rel_scale) * 100;
        const size = list ? 998 * (width_percent / 100) : 400 * (width_percent / 100);

        const returned = getSet(record, "rtrn");
        const received = getSet(record, "rcvd");
        const per = (returned / received) * 100; 

        let _offset = 0;
        if(received === 0 && returned >0){
            _offset = size; 
        }

        return (
            <div
                style={{
                    position: "relative",
                    width: "0px",
                    marginLeft : (list) ? _offset + "px" : "0px",
                    marginBottom : (!list) ? _offset + "px" : "0px",
                    height: "0px",
                    top: list ? "4px" : "0px",
                    right: list ? `${size}px` : "-4px",
                    zIndex: "10"
                }}
            >
                <Tooltip title={<div><div>Returns</div>
                
                    <Space>
                    <div>
                        <div>${addCommas(returned)}</div>
                        {(isFinite(per) && <div>{per.toFixed(1)}%</div>)}
                       
                    </div>
                    <div>
                        <div>cost</div>
                        {(isFinite(per) && <div>of received</div>)}
                        
                    </div>
                </Space>

                
                </div>}>
                    <div
                        onClick={() => setDrilldown("rtrn", record?.id)}
                        className="lighten"
                        style={{
                            width: list ? `${size}px` : "6px",
                            height: list ? "6px" : `${size}px`,
                            backgroundColor: "#505000"
                        }}
                    />
                </Tooltip>
            </div>
        );


    };

    /**
    * Renders the received.
    */
    const drawReceived = (show = true) => {

        const scale = getSet(record, "rcvd_w");
        const width_percent = (scale * rel_scale) * 100;
        // {drawSegment({ key: "onorder_total", label: "Onorder Total" }, "#ff9900")}
        // const size = list ? 998 * (width_percent / 100) : 400 * (width_percent / 100);

        if (!show) {
            return (
                <div
                    style={{
                        opacity: "0",
                        width: list ? `${width_percent}%` : "20px",
                        height: list ? "20px" : `${width_percent}%`,
                        pointerEvents: "none"
                    }}
                />
            );
        }

        return (
            <Tooltip title={drawToolTip({ key: "rcvd", label: "Received" })}>
                <div
                    onClick={() => setDrilldown("rcvd", record?.id)}
                    className="lighten"
                    style={{
                        backgroundColor: "#808000",
                        width: list ? `${width_percent}%` : "20px",
                        height: list ? "20px" : `${width_percent}%`,
                        marginBottom: list ? "-10px" : "0",
                        marginRight: list ? "0" : "-10px"
                    }}
                />
            </Tooltip>
        );
    };

    const drawToolTip = (item) => {
        const title = item?.label || "";
        const dollars = addCommas(getSet(record, item.key, false, "dollars"));
        const copies = addCommas(getSet(record, item.key, false, "unit_copies"));
        const isbns = addCommas(getSet(record, item.key, false, "unique_isbns"));

        return (
            <div>
                <div>{title}</div>
                <Space>
                    <div>
                        <div>${dollars}</div>
                        <div>{copies}</div>
                        <div>{isbns}</div>
                    </div>
                    <div>
                        <div>cost</div>
                        <div>copies</div>
                        <div>ISBNs</div>
                    </div>
                </Space>
            </div>
        );
    };
    
    const drawLabel = () => {

        let ret = "";
        switch (show_which) {
            case "dollars":
                ret = <>${addCommas(getSet(record, "rcvd", false, "dollars"))} <small>cost</small></>
                break;
            case "unit_copies":
                ret = <>{addCommas(getSet(record, "rcvd", false, "unit_copies"))} <small>units</small></>
                break;
            case "unique_isbns":
                ret = <>{addCommas(getSet(record, "rcvd", false, "unique_isbns"))} <small>isbns</small></>
                break;
        }
        return ret;
    }


    const drawOnOrder = (show = true) => {

        const scale = getSet(record, "onorder_total_w");
        const width_percent = (scale * rel_scale) * 100;
        // {drawSegment({ key: "onorder_total", label: "Onorder Total" }, "#ff9900")}
        const size = list ? 998 * (width_percent / 100) : 400 * (width_percent / 100);

        if (!show) {
            return (
                <div
                    style={{
                        opacity: "0",
                        width: list ? `${width_percent}%` : "20px",
                        height: list ? "20px" : `${width_percent}%`,
                        pointerEvents: "none"
                    }}
                />
            );
        }

        return (
            <Tooltip title={drawToolTip({ key: "onorder_total", label: "Onorder" })}>
                <div
                    onClick={() => setDrilldown("onorder_total", record?.id)}
                    className="lighten"
                    style={{
                        backgroundColor: "#ff9900",
                        width: list ? `${width_percent}%` : "20px",
                        height: list ? "20px" : `${width_percent}%`,
                        marginBottom: list ? "-10px" : "0",
                        marginRight: list ? "0" : "-10px"
                    }}
                />
            </Tooltip>
        );
    };


    return (
        <div>
            <Row>
                {(list &&
                    <Col flex={"200px"}>
                        <div style={{ "padding": "0px 0px" }}>
                            <Space>
                                <div style={{ "width": "70px" }}>Received</div>
                                <div style={{ "width": "100px", "textAlign": "right" }}>{drawLabel()}</div>
                            </Space>
                        </div>
                    </Col>
                )}

                <Col flex={"auto"}>
                    <div>
                        <div style={{ display: list ? "block" : "flex" }}>
                            {/* Overall onhand bar */}
                            <div
                                style={{
                                    height: list ? "10px" : "400px",
                                    display: "flex",
                                    flexDirection: list ? "row" : "column-reverse",
                                    alignItems: "flex-end"
                                }}
                            >
                                {drawReceived()}
                                {drawReturn()}
                               
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    );
}
