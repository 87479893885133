import React from "react"
import { Button, Divider, Dropdown, Menu, Space, Tag, Typography } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
export const successColor = "#4f9818"; // green
export const warningColor = "#E77E21"; // orage
export const disabledColor = "#999999"; // grey
export const futureColor = "#189285"; // teal
export const bestsellerColor = "#F12A46"; // red
export const peerColor = "#A52A2A"; // burgandy
export const favColor = "#ffcf17" // yellow
export const highlightColor = "#49b7e0" // lightBlue
export const returnedColor = "#9932cc" // lightBlue


export const getListPrice = (list_price_can, list_price_usa, is_canadian, _br = false) => {
  let _can = (list_price_can > 0) ? "$" + list_price_can : "";
  let _us = (list_price_usa > 0) ? "$" + list_price_usa : "";
  // Add suffix if both exist..
  if (_can && _us) {
      _can = _can + " CA";
      _us = _us + " US"
  }
  let _sep = (_can && _us)  ? " / " : " ";

  if(_br){
    _sep = "";
  }
  return(<div style={{ "fontWeight": "600" }}>{(is_canadian && (_can || _us)) ? <>{_can}{(_br && <br />)}{_sep}{_us}</> : <>{_us}{_sep}{_can}</>}<small> {((_can || _us) && <>{(_br) ? "" : "list price"}</>)}</small></div>)
}

export const determineArticle = (word) => {
  // Convert the word to lowercase for easier comparison
  const lowerWord = word.toLowerCase();

  // Define a list of vowels
  const vowels = ['a', 'e', 'i', 'o', 'u'];

  // Check if the word starts with a vowel sound
  if (vowels.includes(lowerWord[0])) {
    return 'an';
  } else {
    return 'a';
  }
}


export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const slugify = (str) => {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')          // Replace spaces with hyphens
    .replace(/[^\w-]+/g, '')       // Remove non-word characters (except hyphens)
    .replace(/--+/g, '-');         // Replace consecutive hyphens with a single hyphen
}

export const drawDeliveryTime = (min, max) => {
  if (!max) {
    return min;
  }

  if (min === max) {
    return min;
  }

  return (min + "-" + max);

}


export const filterUnique = (array, property) => {

  const seen = {};
  return array.reduce((uniqueArray, currentItem) => {
    const value = currentItem[property];
    if (!seen.hasOwnProperty(value)) {
      seen[value] = true;
      uniqueArray.push(currentItem);
    }
    return uniqueArray;
  }, []);
}


export const drawMSTitleBar = (_stores = [], _title, _index, _divider = true) => {


  if (_stores.length <= 1) {
    return (<></>);
  }

  const menu = (
    <Menu>
      {_stores.map((item, ind) => {
        return (
          <Menu.Item onClick={(e) => {
            setTimeout(() => {
              let element = document.getElementById("store" + ind);
              if (element) {
                let m = element.getBoundingClientRect();
                window.scrollTo({
                  top: m.y - 100 + window.scrollY,
                  left: 0,
                  behavior: 'smooth'
                });
              }
            }, 10)
          }}>{item.name}</Menu.Item>
        )
      })}
    </Menu>
  );

  return (
    <div>
      <div id={"store" + _index} />
      <div className="float-flex">
        <Typography.Title level={5}>{_title}</Typography.Title>
        <Dropdown overlay={menu}>
          <a onClick={(e) => e.preventDefault()}>
            <small>Jump to Store</small>
          </a>
        </Dropdown>
      </div>
      {(_divider && <div style={{ "margin": "0 -23px" }}><Divider style={{ "margin": "15px 0px" }} /></div>)}
    </div >
  )
}

export const drawMSFooter = (_stores = [], _index) => {

  if (_index === (_stores.length - 1)) {
    return (<></>);
  }

  return (<div style={{ "margin": "0px -23px" }}><Divider style={{ "margin": "15px 0px" }} /></div>)

}

export const handleTermSlash = (_str, _encode = false) => {

  if (_encode) {
    return _str.replace("/", "%2f");
  } else {
    return _str.replace("%2f", "/");
  }

}


export const findMedian = (_arr = []) => {
  // sort
  _arr.sort(function (a, b) {
    return a - b;
  });

  if (_arr.length % 2 !== 0) {
    return _arr[Math.floor(_arr.length / 2)];
  }
  else {
    let mid = _arr.length / 2;
    return (_arr[mid - 1] + _arr[mid]) / 2;
  }

}


export const getEANChecksum = (ean) => {
  const sum = (10 - (((Number(ean[0]) + Number(ean[2]) + Number(ean[4]) + Number(ean[6]) + Number(ean[8]) + Number(ean[10])) + ((Number(ean[1]) + Number(ean[3]) + Number(ean[5]) + Number(ean[7]) + Number(ean[9]) + Number(ean[11])) * 3)) % 10) % 10);
  return sum;
}

export const isValidEAN = (ean) => {
  if (!/^[0-9]{13}$/.test(ean)) {
    return false;
  }
  if (Number(ean[12]) !== getEANChecksum(ean)) {
    return false; // check digit doesn't match, bad ean.
  }
  return true;
}

export const containsISBN = (ean) => {
  if (!/^978[0-9]{10}$/.test(ean)) {
    return false;
  }
  return isValidEAN(ean);
}




// export const containsISBN = (_str) => {
//   // Regular expression to match ISBNs
//   const isbnRegex = /^(?=(?:\D*\d){10}(?:(?:\D*\d){3})?$)[\d-]+$/i;
//   // Search the string for a match and return the result
//   return isbnRegex.test(_str);
// }


export const isValidUPC = (_str) => {
  // check if the str is 12 digit number
  let pattern = /^\d{12}$/;
  if (!pattern.test(_str)) {
    return false;
  }

  // calculate the check digit
  let sum = 0;
  for (let i = 0; i < 11; i++) {
    sum += (i % 2 === 0 ? 3 : 1) * _str[i];
  }

  let checkDigit = 10 - (sum % 10);

  // ensure check digit matches the last digit
  if (checkDigit === parseInt(_str[11])) {
    return true;
  } else {
    return false;
  }

}


export const sortAlpha = (_arr, _key) => {
  return _arr.sort((a, b) => {
    let _a = a[_key].toLowerCase();
    let _b = b[_key].toLowerCase();
    if (_a < _b) {
      return -1; // nameA comes before nameB
    }
    if (_a > _b) {
      return 1; // nameA comes after nameB
    }
    return 0; // names are equal
  });
}


export const serialize = (obj) => {
  if (Array.isArray(obj)) {
    return JSON.stringify(obj.map(i => serialize(i)))
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj)
      .sort()
      .map(k => `${k}:${serialize(obj[k])}`)
      .join('|')
  }
  return obj
}

export const flattenObject = (ob) => {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object') {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};


export const serializeJSON = (obj) => {
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      return serializeJSON(value);
    }
    return value;
  }, 2);
}



export const getISBNCount = (_str) => {
  // Regular expression to match ISBNs
  const isbnRegex = /(?:ISBN(?:: ?| ))?((?:97[89])?\d{9}[\dx])/gi;
  const negatedisbnRegex = /(?:ISBN(?:: ?| ))?-(?:(?:97[89])?\d{9}[\dx])/gi;

  const negated = _str.match(negatedisbnRegex);
  if (negated) {
    return 0;
  }

  // Use the regular expression to search the string and get all matches
  const matches = _str.match(isbnRegex);
  // If there are no matches, return 0
  if (!matches) {
    return 0;
  }
  // Return the length of the matches array
  return matches.length;
}


export const sum = (_arr, _key) => {
  return _arr.reduce((a, b) => a + (b[_key] || 0), 0);
}

export const convertLineBreaks = (_str) => {
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  return _str.replace(regex, '<br />');
}


export const getPercentageIncrease = (numA, numB, persision = 2) => {
  if (numA === 0 || numB === 0) {
    return "N/A";
  }
  return (((numA - numB) / numB) * 100).toFixed(persision);
}


export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const getSan = (_corp_id, _stores = []) => {

  let _f = _stores.find(item => item.cid === _corp_id);
  if (_f) {
    return _f.san;
  } else {
    return "";
  }

}

export const bmDate = (_date) => {
  return moment(_date).format("MMM DD-YY")

  // if (moment(_date).format("D").length === 1) {
  //   return moment(_date).format("MMM DD-YY")
  // } else {
  //   return moment(_date).format("MMM DD-YY")
  // }
}

export const getFlag = (_country) => {
  let flag = "https://cdn1.bookmanager.com/i/CA.png"
  switch (_country) {
    case "Canada":
      flag = "CA"
      break;
    case "United Kingdom":
      flag = "CA"
      break;
    case "USA":
      flag = "US"
      break;
    default:
      flag = "CA"
      break;
  }
  return "https://cdn1.bookmanager.com/i/" + flag + ".png";
}

export const cacheBuster = (_first = false) => {
  let cb = Math.round(new Date().getTime() / 1000);
  if (_first) {
    return "?cache=" + cb.toString();
  }

  return "&cache=" + cb.toString();
}

export const getCanIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="-2015 -2000 4030 4030"><title>maple leaf from the flag of Canada</title><path fill="#e00" d="m-90 2030 45-863a95 95 0 0 0-111-98l-859 151 116-320a65 65 0 0 0-20-73l-941-762 212-99a65 65 0 0 0 34-79l-186-572 542 115a65 65 0 0 0 73-38l105-247 423 454a65 65 0 0 0 111-57l-204-1052 327 189a65 65 0 0 0 91-27l332-652 332 652a65 65 0 0 0 91 27l327-189-204 1052a65 65 0 0 0 111 57l423-454 105 247a65 65 0 0 0 73 38l542-115-186 572a65 65 0 0 0 34 79l212 99-941 762a65 65 0 0 0-20 73l116 320-859-151a95 95 0 0 0-111 98l45 863z"></path></svg>
  )
}

export const bytesToSize = (bytes) => {
  let sizes = ['', 'k', 'm', 'gb', 'tb'];
  if (bytes == 0) return '0 Byte';
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + '' + sizes[i];
}

export const addCommas = (_num) => {
  return _num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// React likes unique id's for listed elements so it knows what to draw / update
export const addKeys = (_arr, _key = false) => {
  return (_arr.map((item) => {

    if (_key) {
      item["key"] = item[_key];
    } else {
      item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
    }

    return item;
  }))
}

export const makeKey = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
}

export const ucfirst = (string) => {

  if (string.includes("-")) {
    string = string.split("-");
    string = string.map(item => ucfirst(item));
    return string.join(" ");
  }


  if (string.includes("_")) {
    string = string.split("_");
    string = string.map(item => ucfirst(item));
    return string.join(" ");
  }

  return (string) ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export const trimTrailingSlash = (str) => {
  return str.replace(/\/$/, ''); // Replace trailing slash with an empty string
}


export const interpolateColor = (color1, color2, steps) => {
  const result = [];
  const start = {
      r: parseInt(color1.slice(1, 3), 16),
      g: parseInt(color1.slice(3, 5), 16),
      b: parseInt(color1.slice(5, 7), 16),
  };
  const end = {
      r: parseInt(color2.slice(1, 3), 16),
      g: parseInt(color2.slice(3, 5), 16),
      b: parseInt(color2.slice(5, 7), 16),
  };

  for (let i = 0; i < steps; i++) {
      const r = Math.round(start.r + ((end.r - start.r) * i) / (steps - 1));
      const g = Math.round(start.g + ((end.g - start.g) * i) / (steps - 1));
      const b = Math.round(start.b + ((end.b - start.b) * i) / (steps - 1));
      result.push(`#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`);
  }

  return result;
}


export const parseAuthors = (_authors, _color = "#007EF5", _fontsize = "14px") => {

  if (!_authors || _authors.length === 0) { return "" }
  return _authors.map((_author, index) => {
    let flag = (_author.canadian && window.sitesettings.is_canadian);
    return (<a style={{ "color": _color, "fontSize": _fontsize }} key={index}>{_author.name} {(flag && getCanIcon())}{(index !== _authors.length - 1) ? ", " : ""} </a>);
  });
}

export const parseAuthorsPlain = (_authors) => {
  if (!_authors || _authors.length === 0) { return "" }
  return _authors.map((_author, index) => {
    return (<span key={index}>{_author.name}{(index !== _authors.length - 1) ? ", " : ""} </span>);
  });
}


export const parseSubjectsPlain = (_subjects) => {
  if (!_subjects || _subjects.length === 0) { return "" }
  return _subjects.map((_subject, index) => {
    if (_subject.sub_labels.length > 0) {
      return (<span key={index}>{_subject.subject} / {_subject.sub_labels.map((_sub) => { return (_sub.label) }).join(" / ")}{(index !== _subject.sub_labels.length && " ")} </span>)
    } else {
      return (<span key={index}>{_subject.subject}</span>);
    }
  })
}


export const parseSubjectsWeird = (_subjects, _limit = 30) => {
  if (!_subjects || _subjects.length === 0) { return "" }
  let ret = "";
  // limit to first
  _subjects = [_subjects[0]];
  return _subjects.map((_subject, index) => {
    if (_subject.sub_labels.length > 0) {
      // limit to first sub
      if (_subject.sub_labels.length > 0) {
        ret += _subject.sub_labels[0].bisac.substr(0, 3) + ": " + _subject.sub_labels[0].label;
      }
      return <>{ret}</>
    } else {
      return (<>{_subject.subject.substring(0, _limit)}</>);
    }
  })
}




export const parseSubjects = (_subjects) => {

  if (!_subjects || _subjects.length === 0) { return "" }
  return _subjects.map((_subject, index) => {
    let ss = _subject.sub_labels.filter(item => item.label !== "");
    if (ss.length > 0) {
      return (<div key={index}><span className="c" style={{ "fontWeight": "600" }}>{_subject.subject}</span> / {ss.map((_sub) => { return (_sub.label) }).join(" / ")}
        {((index + 1) !== _subjects.length && " ")}
      </div>)
    } else {
      return (<div key={index} className="c" style={{ "fontWeight": "600" }}>{_subject.subject}</div>);
    }
  })
}

export const parseSubjectsList = (_subjects) => {
  if (!_subjects || _subjects.length === 0) { return "" }
  return _subjects.map((_subject, index) => {
    if (_subject.sub_labels.length > 0) {
      // has sub
      return (<span key={index}><strong className="c">{_subject.subject}</strong> <br /> {_subject.sub_labels.map((_sub, i) => { return ((_sub.label) ? <span key={i}>{_sub.label}<br /></span> : <></>) })}</span>)
    } else {
      return (<span key={index}><strong className="c">{_subject.subject}</strong><br /></span>);
    }
  })
}

export const parseSubjectsBisacs = (_subjects) => {

  if (!_subjects || _subjects.length === 0) { return "" }
  return _subjects.map((_subject, index) => {
    if (_subject.sub_labels.length > 0) {
      // has sub
      return (<span key={index}><strong className="c">{_subject.subject}</strong> <br /> {_subject.sub_labels.map((_sub, i) => { return ((_sub.label) ? <span key={i}>{_sub.label} <em><small>{_sub.bisac}</small></em><br /></span> : <></>) })}</span>)
    } else {
      return (<span key={index}><strong className="c">{_subject.subject}</strong><br /></span>);
    }
  })
}






export const parseRank = (_rank) => {
  if (!_rank) { return "" }
  if (_rank.hasOwnProperty('bestseller')) {
    return (<span style={{ "color": bestsellerColor }}>{"#" + _rank.bestseller + " in bestsellers"}</span>);
  }
  if (_rank.hasOwnProperty('future')) {
    return (<span style={{ "color": futureColor }}>{"#" + _rank.future + " in future releases"}</span>);
  }
  return "";
}


export const getQuantity = (_isbn, _cart) => {


  let find = _cart.find((item => item.isbn == _isbn));

  if (!find) {
    return [];
  }


  return find.suppliers.map((itm => {
    return {
      quantity: itm.quantity,
      supplier: itm.location
    }
  }))
}


export const sumByKey = (arr, uniqueKey, sumKey) => {
  const result = [];
  arr.forEach((item) => {
    const uniqueValue = item[uniqueKey];
    const sumValue = item[sumKey];
    // Check if unique key already exists in result array
    const existingItem = result.find((r) => r[uniqueKey] === uniqueValue);
    if (existingItem) {
      // If unique key exists, add sum value to existing item
      existingItem[sumKey] += sumValue;
    } else {
      // If unique key doesn't exist, add new item to result array
      const newItem = {};
      newItem[uniqueKey] = uniqueValue;
      newItem[sumKey] = sumValue;
      result.push(newItem);
    }
  });

  return result;
}

export const drawQuantity = (_isbn, _cart) => {
  let btn_arr = getQuantity(_isbn, _cart)
  let _color = (btn_arr.length > 0) ? "#3C8617" : "#333333"
  return (
    <Tag style={{ "lineHeight": "16px", "cursor": "pointer", "textAlign": "center", "marginRight": "0px" }} color={_color}>
      {((btn_arr.length === 0) && "Order")}
      {btn_arr.map((itm, index) => {
        return <span key={index}>{itm.quantity} - {itm.supplier}<br /></span>
      })}
    </Tag>
  )
}


export const removeNulls = (obj) => {
  let newObj = {};
  for (const key in obj) {
    if (obj[key] !== null) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}


// Order array by groups, each group ordered alphabetically on separate key
export const groupSort = (_arr, _group_key, _group_order, _alpha_key) => {
  let _group_map = {};
  for (let i = 0; i < _group_order.length; i++) {
    _group_map[_group_order[i]] = i;
  }
  // Sort the objects by group and then by the alpha key within each group
  _arr.sort((a, b) => {
    if (_group_map[a[_group_key]] < _group_map[b[_group_key]]) {
      return -1;
    } else if (_group_map[a[_group_key]] > _group_map[b[_group_key]]) {
      return 1;
    } else {
      return a[_alpha_key].localeCompare(b[_alpha_key]);
    }
  });
  // Return the sorted array
  return _arr;
}


function sortObjectsByGroup(objects, groupOrder) {
  // Create an object to hold the group order
  let groupOrderMap = {};
  for (let i = 0; i < groupOrder.length; i++) {
    groupOrderMap[groupOrder[i]] = i;
  }

  // Sort the objects by group and then by title within each group
  objects.sort((a, b) => {
    if (groupOrderMap[a.group] < groupOrderMap[b.group]) {
      return -1;
    } else if (groupOrderMap[a.group] > groupOrderMap[b.group]) {
      return 1;
    } else {
      return a.title.localeCompare(b.title);
    }
  });

  // Return the sorted array of objects
  return objects;
}


export const isNumeric = (str) => {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


export const toUriHash = (str) => {
  // Encode the string to Base64
  let base64 = Buffer.from(decodeURI(str)).toString('base64');
  // Replace characters that are not URI-friendly
  let uriEncoded = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');

  return uriEncoded;
}


export const fromUriHash = (hash) => {
  // Replace URI-friendly characters back to their original form
  let base64 = hash.replace(/\-/g, '+').replace(/\_/g, '/');
  // Add padding if needed
  while (base64.length % 4) {
    base64 += '=';
  }
  // Decode the Base64 string
  let str = Buffer.from(base64, 'base64').toString();
  return str;
}



export function downloadImage(url, fileName) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  }
  xhr.send();
}




export const getGroups = (_stores = true) => {
  const blank = [" "]
  const stores = window.sitesettings.stores.map((item, index) => { return item.store_number.toString() })
  const groups = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  if (stores.length > 1) {
    return stores.concat(blank.concat(groups));
  } else {
    return blank.concat(groups);
  }

}


export const pathToObj = (_path, _map) => {
  let _arr = _path.split("/");
  let _keys = Object.keys(_map).map(item => _map[item]);
  let _obj = {};

  _keys.forEach(_key => {
    _obj[_key] = "";
  })

  for (let i = 0; i < _arr.length; i++) {
    if (i < (_arr.length - 1)) {
      if (_obj.hasOwnProperty(_arr[i])) {
        _obj[_arr[i]] = _arr[(i + 1)];
      }
    }
  }

  let _ret = {}
  Object.keys(_map).forEach(_key => {
    if (_obj.hasOwnProperty(_map[_key])) {
      _ret[_key] = _obj[_map[_key]];
    }
  })

  return _ret;




}

export const objToPath = (_obj, _map) => {
  let _path = "filter";
  Object.keys(_obj).forEach(_key => {
    if (_obj[_key]) {
      _path += "/" + _map[_key] + "/" + _obj[_key];
    }
  })
  return _path;
}






export const objectToPath = (_obj, firstpage = false) => {
  let location = "/filter";

  // remove sub-subjects if there is no top-level subject
  if (_obj.hasOwnProperty("c") && _obj.c.length === 0) {
    if (_obj.hasOwnProperty("d") && _obj.d.length > 0) {
      delete _obj.d;
    }
  }

  if (_obj.hasOwnProperty("c") && _obj.hasOwnProperty("d")) {
    for (let i = 0; i < _obj.d.length; i++) {
      if (!_obj.c.includes(_obj.d[i].substr(0, 3))) {
        _obj.d = _obj.d.filter(itm => itm !== _obj.d[i]);
      }
    }
  }

  if (firstpage) {
    _obj.o = 0;
  }

  //(June 27, 2023) George - 
  //Created a loop with a couple checks to make the code more futureproof for new filters
  //Also differentiates between arrays and non-arrays for the different _obj values, to handle correctly

  Object.keys(_obj).forEach((key) => {
    //runs if value is an array to properly append all values to url
    if (Array.isArray(_obj[key]) && _obj[key].length > 0) {
      if (_obj[key].includes("AND") && _obj[key].length < 3) {
        // strip and
        _obj[key] = _obj[key].filter(itm => itm !== "AND");
      }
      location += "/" + key.toString() + "/" + _obj[key].join("/")
      //runs for non-array values, like limit and offset
    } else if (_obj.hasOwnProperty(key) && _obj[key]) {
      //checks to see if the value at key is a string for encoding purposes
      if (typeof _obj[key] !== "string") {
        //run when value is not a string, so it can be turned into one for the sake of the url
        //examples are limit or offset, which are numbers
        //specific if to make sure firstpage values aren't being added to the url
        if ((key === 'l' && _obj[key] !== 20) || (key === 'o' && _obj[key] !== 0)) {
          location += "/" + key.toString() + "/" + _obj[key].toString();
        }
      } else {
        //runs when it is a string, so it can be properly encoded
        //example would be filter x
        location += "/" + key.toString() + "/" + encodeURI(_obj[key]);
      }
    }
  })

  return location;
}



export const generateTimeOptions = () => {
  const times = [];
  for (let i = 0; i < 24 * 2; i++) {
    const hours = Math.floor(i / 2);
    const minutes = (i % 2) * 30;
    const period = hours < 12 ? 'AM' : 'PM';
    const displayHours = hours % 12 === 0 ? 12 : hours % 12;
    const displayMinutes = minutes === 0 ? '00' : minutes;
    const time = `${displayHours}:${displayMinutes} ${period}`;
    times.push(time);
  }
  return times;
};


export const numSuffix = (number) => {

  const numStr = number.toString();
  // Handle special cases for numbers ending in 11, 12, or 13
  if (number % 100 >= 11 && number % 100 <= 13) {
    return numStr + "th";
  }

  // Determine the last digit of the number
  const lastDigit = number % 10;
  let suffix;
  switch (lastDigit) {
    case 1:
      suffix = "st";
      break;
    case 2:
      suffix = "nd";
      break;
    case 3:
      suffix = "rd";
      break;
    default:
      suffix = "th";
  }

  // Return the number as a string with the appropriate suffix
  return numStr + suffix;
}


export const rruleToObj = (_rule = "") => {

  // Split the string into key-value pairs
  const keyValuePairs = _rule.split(';');

  const rruleObject = keyValuePairs.reduce((acc, pair) => {
    const [key, value] = pair.split('=');
    acc[key.toLowerCase()] = isNaN(value) ? value : Number(value);
    return acc;
  }, {});


  // Destructure
  const additionalObject = { 
    freq: "",
    interval : "",
    byday : "",
    bymonth : "",
    until : "",
    bymonthday : "",
    count : "" 
  };

  // Merge the objects
  return { ...additionalObject, ...rruleObject };


}





export const getFilterSet = (_key) => {
  let _saved = Cookies.get("filtersets");
  if (!_saved) return false;
  _saved = JSON.parse(_saved);
  if (_saved.hasOwnProperty(_key)) {
    return JSON.parse(_saved[_key]);
  } else {
    return false;
  }
}

export const saveFilterSet = (_key, _value) => {

  let _saved = Cookies.get("filtersets");
  let filtersets = {}
  // no memory at all - save first
  if (!_saved) {
    filtersets = {
      [_key]: JSON.stringify(_value)
    }
    Cookies.set("filtersets", JSON.stringify(filtersets))
    return filtersets[_key];
  }

  _saved = JSON.parse(_saved);
  // memory exists, no key 
  _saved[_key] = JSON.stringify(_value);
  Cookies.set("filtersets", JSON.stringify(_saved))
  return _saved[_key];
}


export const getMemory = (_key) => {
  let _saved = Cookies.get("memory");
  if (!_saved) return [];
  _saved = JSON.parse(_saved);
  if (_saved.hasOwnProperty(_key)) {
    return _saved[_key];
  } else {
    return []
  }
}

export const saveMemory = (_key, _value) => {
  let _saved = Cookies.get("memory");
  let memory = {}
  // no memory at all - save first
  if (!_saved) {
    memory = {
      [_key]: [_value]
    }
    Cookies.set("memory", JSON.stringify(memory))
    return memory[_key];
  }

  _saved = JSON.parse(_saved);
  // memory exists, no key 
  if (!_saved.hasOwnProperty(_key)) {
    _saved[_key] = [_value]
    Cookies.set("memory", JSON.stringify(_saved))
    return _saved[_key];
  }

  if (_saved[_key].includes(_value)) {
    // not unique, put it at the top
    _saved[_key] = _saved[_key].filter(item => item !== _value);
    _saved[_key].unshift(_value);
    Cookies.set("memory", JSON.stringify(_saved));
    return _saved[_key];
  }

  _saved[_key].unshift(_value);
  _saved[_key] = _saved[_key].slice(0, 6); // limit to 5
  Cookies.set("memory", JSON.stringify(_saved));
  return _saved[_key];

}


export const getFilterCount = (_path) => {

  let obj = pathToObject(_path);
  let count = 0;

  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      count += obj[key].length;
    }
  })

  return count;

}

function isLetter(c) {
  return c.toLowerCase() != c.toUpperCase();
}

export const pathToObject = (_path, _save = "") => {


  let returnObject = {
    o: 0, // offset
    l: 20, // limit
    t: "", // term
    k: "", // type
    v: "", // order
    p: "", // preset
    z: "" // series
  }

  if (_save) {
    let _lm = parseInt(Cookies.get(_save));
    if (!isNaN(_lm)) {
      returnObject.l = _lm;
    }
  }

  let startIndex = _path.search(/filter/i);
  let p = _path.substr(startIndex, _path.length);
  let split = p.split("/");
  let key;
  let ka = ["b"];
  for (let i = 0; i < split.length; i++) {
    if (split[i].length === 1 && isLetter(split[i])) {
      key = split[i];
    } else {
      if (!returnObject.hasOwnProperty(key)) {
        if (key) {
          returnObject[key] = [];
        } else {
          continue;
        }
      }
      if (Array.isArray(returnObject[key])) {

        returnObject[key] = [...returnObject[key], split[i]];
      } else {
        if (key === "o" || key === "l") {

          returnObject[key] = parseInt(split[i]);
        } else {
          returnObject[key] = split[i];
        }
      }
      (ka.indexOf(key) === -1 && ka.push(key));
    }
  }

  return returnObject;
}


export const pluralize = (count, noun, suffix = 's') => {
  return (count === 1) ? noun : noun + suffix;
}



export const adjustOpacity = (hexCode, amount) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${amount / 100})`;
};

export const adjustColor = (col, amt) => {

  var usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);

}