import { Space, Button } from "antd";
import moment from "moment-timezone";
import React from "react";
import { apiDownload } from "../../utils/Api";

export default function ActivityReport(props) {

    const { start_date = moment().unix(), end_date = moment.unix() } = props;

    // Jan 1 of last year until Dec 31 of last year
    const last_year_start = moment().subtract(1, 'year').startOf('year').startOf("day").unix();
    const last_year_end = moment().subtract(1, 'year').endOf('year').endOf("day").unix();

    // Jan 1 of this year until June 30 of this year
    const current_year_start = moment().startOf('year').startOf("day").unix();
    const current_year_june = moment().month(5).endOf('month').endOf("day").unix();

    const getPdfForDateRange = (_type = "range") => {

        let start_ts;
        let end_ts;
        let is_jan_report;

        switch (_type) {
            case "last":
                start_ts = last_year_start;
                end_ts = last_year_end;
                is_jan_report = true;
                break;
            case "current":
                start_ts = current_year_start;
                end_ts = current_year_june;
                break;
            default:
            case "range":
                start_ts = start_date;
                end_ts = end_date;
                break;
        }

        apiDownload({ start_ts: start_ts, end_ts: end_ts, is_jan_report: is_jan_report }, "order/getPdfForDateRange");

    }


    return (
        <>
            <div class="ant-statistic">
                <div class="ant-statistic-title"><small>Ordering Activity</small></div>
                <div>
                    <Space>
                        <Button onClick={() => getPdfForDateRange("range")} type="primary" size="small" ><small>Generate PDF from date range</small></Button>
                        <Button onClick={() => getPdfForDateRange("last")} type="primary" size="small" ><small>January Billing Report</small></Button>
                        <Button onClick={() => getPdfForDateRange("current")} type="primary" size="small" ><small>July Billing Report</small></Button>
                    </Space>
                </div>
            </div>
        </>
    )
}