import { Button, Form, InputNumber, Modal, Space, Typography } from "antd";
import React, { useState } from "react";
import Overlay from "../../components/Overlay";

export default function TurnsSelector(props) {

    const {
        turns = 4,
        reset = 4,
        onChange = () => { }
    } = props;



    const DrawModal = (props) => {

        const [inc, setInc] = useState(props.turns);


        return (
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Turn Benchmark</Typography.Title>
                <p>This benchmark will adjust the onhand bars relative to sales. e.g. If a sales turn rate matches the benchmark, the sales and onhand bars will be of equal length.</p>
                <Form.Item label="Turns">
                    <InputNumber onKeyDown={(e) => { 
                        if(e.key === "Enter"){
                            props.recalibrate(e.target.value);
                            props.close();
                        }
                    }} value={inc} onChange={(e) => setInc(e)} onFocus={(e) => e.target.select()} autoFocus precision={1} min={1} max={9.9} defaultValue={turns} step={0.1} />
                </Form.Item>
                <div className="float-flex">
                    <div>
                        <Button onClick={() => props.close()}>Cancel</Button>
                    </div>
                    <Space>
                        <Button onClick={() => {

                            props.recalibrate(reset);
                            props.close();

                        }} danger>Reset to {reset}</Button>
                        <Button onClick={() => {
                            props.recalibrate(inc);
                            props.close();
                        }} type="primary">OK</Button>
                    </Space>
                </div>
            </div>
        )
    }

    return (
        <Overlay width={350} component={<DrawModal recalibrate={onChange} turns={turns} />}>
            <Button size="small"><small>{turns}</small></Button>
        </Overlay>
    )


}