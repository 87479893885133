import React from "react";


const formatNumber = (num) => {
    if (num >= 1000000) return `${(num / 1000000).toFixed(2)}M`;
    if (num >= 1000) return `${(num / 1000).toFixed(0)}k`;
    return num.toString();
};

const AnalyticsScale = ({ results, filters }) => {

    const tickCount = 20;
    let graph_using = "";
    let prefix = "$";

    switch (filters.show_which) {
        case "dollars":
            graph_using = "dollars";
            prefix = "$";
            break;
        case "unit_copies":
            graph_using = "units";
            prefix = "";
            break;

        case "unique_isbns":
            graph_using = "titles";
            prefix = "";
            break
    }


    const maxValue = results.results.rows.reduce((max, obj) => {
        const value = obj[graph_using]["sandr_total"];
        return value > max ? value : max;
    }, 0);

    const scaleY = results.results.rows.reduce((max, obj) => {
        const value = obj[graph_using]["sandr_total_w"] * obj[graph_using]["rel_scale"];
        return value > max ? value : max;
    }, 0);

    const scale_height = (400 * scaleY);
    const midValue = maxValue / 2;
    const tickSpacing = 100 / (tickCount - 1);
    return (
        
            
            <div style={{ "height": "430px", "justifyContent" : "end" }} className="bm-ani-scale-container">
                <div className="bm-ani-label">&nbsp;</div>
                <div className="bm-ani-scale" style={{"height" : scale_height + "px"}}>
                    <div className="bm-ani-tick" style={{ top: "0%" }}>
                        <span className="bm-ani-tick-label" style={{ "marginTop": "-20px" }}>{prefix}{formatNumber(maxValue)}</span>
                    </div>
                    <div className="bm-ani-tick" style={{ top: "50%" }}>
                        <span className="bm-ani-tick-label">{prefix}{formatNumber(midValue)}</span>
                    </div>
                    <div className="bm-ani-tick" style={{ top: "100%" }}>
                        <span className="bm-ani-tick-label">{prefix}0</span>
                    </div>
                    <div className="bm-ani-line"></div>
                    {[...Array(tickCount)].map((_, index) => (
                        <div
                            key={index}
                            className="horizontal-tick"
                            style={{ top: `${index * tickSpacing}%` }}
                        ></div>
                    ))}
                </div>
            </div>
       
    );
};


export default AnalyticsScale;
