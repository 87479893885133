
// store/cart/setForIsbn
// mandatory params: isbn, data.
// data is an array like
// [
// 			'id' => (int),
// 			'supplier' => (string),
// 			'sell_price' => (float),
// 			'pubprice' => (float),
// 			'discount' => (string),
// 			'order_group' => (string),
// 			'multistore_quantity' => [
// 				['san' => 'quantity']
// 			],
// 			'shipto_name' => (string),
// 			'cust_po' => (string),
// 			'order_notes' => (string)
// ]
// optional params: cat_1, cat_2, notes


import React, { useState, useEffect, useRef } from "react";
import { Select, Input, Space, Menu, Button, Table, Row, Col, AutoComplete, Tag, Typography, Divider, Dropdown, message, Tooltip } from "antd";
import { CloseOutlined, CloseCircleFilled, CaretDownOutlined, CaretUpOutlined, CopyOutlined } from '@ant-design/icons';
import { getGroups, getMemory, isNumeric, saveMemory } from "../utils/Utils";
import Pubstock from "./Pubstock";
import { makeKey } from "../utils/Utils";
import { useSession } from "../utils/Session";
import Splitter from "../utils/Splitter";
import conditional from "../utils/conditional";
import OrderSearchUser from "./OrderSearchUser";
import CategorySelector from "./CategorySelector";
import { apiCall } from "../utils/Api";
import QuickPick from "./QuickPick";
import Cookies from "js-cookie";
import { usePrefs } from "../utils/Prefs";
import DropMenu from "./DropMenu";
import { useForm } from "antd/lib/form/Form";
import RememberInput from "./RememberInput";
import NumPicker from "../tools/NumPicker";
import GroupPicker from "../tools/GroupPicker";
import BMNoteCats from "./title/BMNoteCats";
import Conditions from "./Conditions";


export default function TestOrderBox(props) {

    const { data = {}, isbn, close = () => { }, wrap = false, cart_update = false, setResults = () => {} } = props;
    const { series = "", sales = [] } = data;
    const { suggested_bm_data = { class_1: "", class_1: "" } } = data;
    const [timer, setTimer] = useState(false);
    const [rerender, setRerender] = useState(false);
    const is_multistore = (window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).length > 1);
    const [session, setSession] = useSession();
    const { price = "", list_price_usa = "", list_price_can = "" } = data;

    const [quantityInput, setQuantityInput] = useState(false);

    const [condition, setCondition] = useState("");
    let _default_notes = "";
    let _default_class_1 = "";
    let _default_class_2 = "";

    if (sales.length > 0) {
        if (sales[0].hasOwnProperty("bm_data")) {
            if (sales[0].bm_data.hasOwnProperty("notes")) {
                if (sales[0].bm_data.notes !== null) {
                    _default_notes = sales[0].bm_data.notes || "";
                }
            }
            if (sales[0].bm_data.hasOwnProperty("class_1")) {
                if (sales[0].bm_data._default_class_1 !== null) {
                    _default_class_1 = sales[0].bm_data.class_1 || "";
                }
            }
            if (sales[0].bm_data.hasOwnProperty("class_2")) {
                if (sales[0].bm_data._default_class_2 !== null) {
                    _default_class_2 = sales[0].bm_data.class_2 || "";
                }
            }
        }
    }

    const updateCondition = (_cond) => {
        setCondition(_cond);
        let _note = ""
        let _category = ""
        let _subcategory = ""
        let _find = sales.findIndex(item => {
            if (item.hasOwnProperty("bm_data")) {
                if (item.hasOwnProperty("code")) {
                    if (item.code === _cond) {
                        if (item.bm_data.hasOwnProperty("notes")) {
                            _note = item.bm_data.notes
                        }
                        if (item.bm_data.hasOwnProperty("class_1")) {
                            _category = item.bm_data.class_1;
                        }
                        if (item.bm_data.hasOwnProperty("class_2")) {
                            _subcategory = item.bm_data.class_2;
                        }
                        return true;
                    }
                }
            }
        })
        if (_find > -1) {
            setNote(_note);
            setCategory(_category);
            setSubCategory(_subcategory);
        } else {
            setNote(_default_notes);
            setCategory(_default_class_1);
            setSubCategory(_default_class_2);
        }
    }

    const getStoreNum = () => {
        let _f = window.sitesettings.stores.find(item => item.cid === session.store_id);
        if (_f) {
            if (_f.hasOwnProperty("store_number")) {
                return _f.store_number;
            } else {
                return 0
            }
        } else {
            return 0;
        }
    }

    const storeNum = getStoreNum();
    const [selectedFocus, setSelectedFocus] = useState(false)
    const [expanded, setExpanded] = useState([]);
    const [selectedKey, setSelectedKey] = useState("");
    const [view, setView] = useState("notes");
    const [defaultKey, setDefaultKey] = useState(makeKey());
    const [loading, setLoading] = useState(false);
    const [currentSave, setCurrentSave] = useState({})
    // Bisac
    const [category, setCategory] = useState(_default_class_1);
    const [subCategory, setSubCategory] = useState(_default_class_2);
    const [note, setNote] = useState(_default_notes);

    const [prefs, setPrefs] = usePrefs();

    const setSave = () => {
        setCurrentSave(saveOrder(false));
    }

    useEffect(setSave, [])

    function isObject(val) {
        return val instanceof Object;
    }

    const saveOrder = (_send = true, _suppliers_obj = selectedSuppliers, _check = false) => {
        let obj = {};
        obj.isbn = isbn;

        if (note) {
            saveMemory("inventory_note", note);
        }
        obj.data = [];
        _suppliers_obj.forEach((item, index) => {
            if (!item.supplier || !item.quantity) {
                // missing required fields, ignore
                return;
            }
            let _dataitem = {}
            if (item.hasOwnProperty("id") && item.id) {
                _dataitem.id = item.id;
            } else {
                // for new rows
                _dataitem.id = "";
            }
            _dataitem.quantity = item.quantity;
            _dataitem.supplier = item.supplier;
            if (item.sell_price) {
                saveMemory("sell_price", item.sell_price);
            }
            _dataitem.sell_price = item.sell_price;
            _dataitem.pubprice = item.supplier_price;
            _dataitem.discount = item.discount;
            _dataitem.order_group = item.group;
            // optional
            _dataitem.shipto_name = item.customer_name;
            _dataitem.cust_po = item.customer_po;
            _dataitem.order_notes = item.order_note;
            _dataitem.multistore_quantity = [];

            // not a multistore, but set multistores array qty to match item qty
            if (!is_multistore) {
                item.stores = [{
                    [window.sitesettings.stores.filter(item => item.cart_active || item.is_primary)[0].san]: _dataitem.quantity
                }];
            }

            if (item.stores.length > 0 && isObject(item.stores[0])) {


                _dataitem.multistore_quantity = item.stores;
            } else {
                window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).forEach((itn, ind) => {
                    _dataitem.multistore_quantity.push(
                        {
                            [itn.san]: item.stores[ind] || 0
                        }
                    )
                });
            }
            obj.data.push(_dataitem);
        })

        // convert data to json

        obj.data = JSON.stringify(obj.data);

        if (_send) {
            if ((_check && (JSON.stringify(obj) === currentSave))) {
                //JSON.stringify(obj) === currentSave
                return;
            }
            setLoading(true);
            apiCall("cart/setForIsbn", obj, (_status, _result) => {
                if (_status) {
                    setCurrentSave(JSON.stringify(_result));

                    if(!wrap && cart_update){
                        // set on underflows
                        setSession({ ...session, "cart": _result });
                    }


                   
                    if (wrap) {
                        // Setting the session is slow so only use it on the pop-ups.  
                        setSession({ ...session, "cart": _result });
                        // Clear prefs
                        let _p = { ...prefs }
                        if (_p.hasOwnProperty("cart")) {
                            delete _p.cart;
                        }
                        setPrefs(_p);
                    }
                    setPrefs({ ...prefs, "cart": _result });
                    //close();
                    //message.success("Cart updated.")
                } else {
                    message.error(_result.error);
                }
                setLoading(false);
            })
        } else {
            return JSON.stringify(obj);
        }
    }

    const saveSplitter = (_key, _stores, _qty) => {
        let m = [...selectedSuppliers];
        let _ind = m.findIndex(item => item.key === _key);
        if (_ind > -1) {
            m[_ind].stores = _stores;
            m[_ind].quantity = _qty
        }
        saveOrder(true, m);
    }

    const createNewSupplier = (_key) => {
        return (
            {
                "key": _key,
                "id": "",
                "quantity": 1,
                "supplier": "",
                "group": "O",
                "supplier_price": "",
                "discount": "",
                "sell_price": "",
                "stores": window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).map((item, index) => {

                    return { [item.san]: (item.is_primary) ? 1 : 0 }
                }),
                "profit_margin": "",
                "exchange": 1,
                "customer_name": "",
                "customer_po": "",
                "order_note": ""
            }
        )
    }

    let init_suppliers = session.cart.find(item => item.isbn === isbn);

    const initialSuppliers = (init_suppliers) ? init_suppliers.suppliers.map(item => {
        return (
            {
                "key": (item.id) ? item.id : makeKey(),
                "id": item.id,
                "quantity": parseInt(item.quantity),
                "supplier": (item.location.length === 5) ? item.location.substr(0, 3) : item.location,
                "group": item.order_grp,
                "supplier_price": item.supplier_price,
                "discount": item.discount,
                "sell_price": item.sell_price,
                "stores": item.multistore_quantity,
                "profit_margin": item.profit_margin,
                "exchange": item.exchange,
                "customer_name": item.shipto_name,
                "customer_po": item.cust_po,
                "order_note": item.order_notes
            })
    }) : [];



    const [selectedSuppliers, setSelectedSuppliers] = useState(initialSuppliers.concat([createNewSupplier(defaultKey)]));


    const resetSelected = () => {
        if (initialSuppliers.length === 0) {
            setSelectedSuppliers(initialSuppliers.concat([createNewSupplier(defaultKey)]));
        } else {
            setSelectedSuppliers(initialSuppliers);
        }
    }

    useEffect(resetSelected, [session.cart])

    const is_saved = (JSON.stringify(initialSuppliers.filter(item => item.quantity > 0), null, 2) === JSON.stringify(selectedSuppliers.filter(item => item.quantity > 0), null, 2))

    const timerSaveOrder = () => {
        if (window.saveTimout) {
            window.clearTimeout(window.saveTimout);
        }
        window.saveTimout = window.setTimeout(saveOrder, 500);
    }

    const selectSupplier = (_supplier, _new = false, _increment_if_exists = true, _overide_group = "", _focus = false) => {
        // Set Notes Field if it matches the suppliers conditions in settings
        let _supacct = _supplier.acct;
        if (_supacct.length === 5) {
            _supacct = _supacct.substr(0, 3);
        }
        let _f = window.sitesettings.supplier_conditions.find(item => item.supplier_code === _supacct);
        if (_f) { updateCondition(_f.condition_code); } else {
            if (condition) {
                updateCondition("");
            }
        }
        setPrefs({ ...prefs, "last_used": _supplier.acct })
        // tobias (13jun2023): local prefs isn't updated by setPrefs so manually sync their state (see web/issues#1902)
        prefs.last_used = _supplier.acct;
        // check if matches group / supplier then increment
        // qp, cookie or O
        let _group = session.quick_picks.find(item => item.supplier === _supplier.acct);
        _group = (_group) ? _group.group : (getMemory("group").length > 0) ? getMemory("group")[0] : "O";
        let check = selectedSuppliers.find(item => (item.group === _group && item.supplier.substr(0, 3) === _supplier.acct.substr(0, 3)));
        if (check && _increment_if_exists) {
            setSelectedKey(check.key);
            if (_focus) {
                setSelectedFocus(check.key);
                if (Cookies.get("pin_splitter") === "yes") {
                    setExpanded([check.key])
                }
            } else {
                setSelectedFocus(false)
            }
            updateOrder(check.key, "quantity", parseInt(check.quantity) + 1);
            timerSaveOrder();
            //saveOrder();
            return;
        }

        if (!_increment_if_exists) {
            _group = _overide_group;
        }

        if (!currentPrice) {
            setCurrentPrice(_supplier.sell_price);
        }

        let key = makeKey();
        setSelectedKey(key);
        if (_focus) {
            setSelectedFocus(key);
            if (Cookies.get("pin_splitter") === "yes") {
                setExpanded([key])
            }
        } else {
            setSelectedFocus(false)
        }
        if (_new) {
            let n = createNewSupplier()
        }
        let supplier = {
            "key": key,
            "id": "",
            "quantity": 1,
            "supplier": (_supplier.acct.length === 5) ? _supplier.acct.substr(0, 3) : _supplier.acct,
            "group": _group,
            "stores": window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).map((item, index) => {
                return { [item.san]: (item.is_primary) ? 1 : 0 }
            }),
            "supplier_price": (_supplier.supplier_price) ? _supplier.supplier_price : 0,
            "discount": (_supplier.discount) ? _supplier.discount : 0,
            "sell_price": (currentPrice) ? currentPrice : _supplier.sell_price,
            "profit_margin": (_supplier.profit_margin) ? _supplier.profit_margin : 0,
            "exchange": (_supplier.exchange) ? _supplier.exchange : 1,
            "customer_name": "",
            "customer_po": "",
            "order_note": ""
        }
        let m = [...selectedSuppliers];
        let _find = m.find(item => item.supplier === "");
        let u = 0;
        if (_find) { u = 1; }
        if (m[m.length - 1].supplier === "") {
            m.splice(selectedSuppliers.length - u, 0, supplier)
        } else {
            m.splice(selectedSuppliers.length - u, 0, supplier)
        }
        if (m.length > 1 && _find) {
            // remove it
            m = m.filter(item => item.supplier !== "");
        }
        setSelectedSuppliers(m);
        saveOrder(true, m);
    }

    let add_new = false;
    const [currentPrice, setCurrentPrice] = useState(0);
    const updatePrice = (_price, _updateSupplier = false) => {
        setCurrentPrice(_price);
        let _sup = [...selectedSuppliers]
        for (let i = 0; i < _sup.length; i++) {
            // don't update the blank
            if (_sup[i].quantity) {
                if (_updateSupplier) {
                    // update supplier price
                    _sup[i].supplier_price = _price;
                } else {
                    // update sell price
                    _sup[i].sell_price = _price;
                }
                let new_profit_margin = calculateProfitMargin(_price, _sup[i].supplier_price, _sup[i].discount, _sup[i].exchange);
                _sup[i].profit_margin = new_profit_margin;
            }
        }
        setSelectedSuppliers(_sup);
    }

    const updateOrder = (_key, _field, _value, _save = false) => {
        let _order = selectedSuppliers.find(item => item.key === _key);
        let _index = selectedSuppliers.findIndex(item => item.key === _key);
        switch (_field) {
            case "group":
                saveMemory("group", _value);
                break;
            case "quantity":
                // ensure it's a number, flag if not a number
                _value = (isNaN(_value)) ? "~" : _value;
                break;
            case "sell_price":
                // ensure it's a number, flag if not a number
                _value = (isNaN(_value)) ? "~" : _value;
                // re-caulutate profit margin if not flagged
                if (_value !== "~") {
                    let new_profit_margin = calculateProfitMargin(_value, _order.supplier_price, _order.discount, _order.exchange);
                    _order.profit_margin = new_profit_margin;
                }
                break;
            case "profit_margin":
                // ensure it's a number, flag if not a number
                _value = (isNaN(_value)) ? "~" : _value;
                // re-caulutate sell price if not flagged
                if (_value !== "~") {
                    if (_value < -1) {
                        _value = 0;
                    }
                    if (_value > 99) {
                        _value = 99;
                    }
                    let new_sell_price = calculateSellPrice(_order.supplier_price, _order.discount, _value, _order.exchange);
                    _order.sell_price = new_sell_price;
                }
            case "supplier_price":
                // ensure it's a number, flag if not a number
                _value = (isNaN(_value)) ? "~" : _value;
                // re-caulutate sell price & profit margin if not flagged
                if (_value !== "~") {
                    let new_profit_margin = calculateProfitMargin(_order.sell_price, _value, _order.discount, _order.exchange);
                    _order.profit_margin = new_profit_margin;
                }
                break;
            case "discount":
                if (_value == 'BX' || _value == 'RG' || _value == '') {
                    _value = 40;
                }
                if (_value == 'NT') {
                    _value = 0;
                }
                _value = _value.replace(".", "");
                // ensure it's a number, flag if not a number
                _value = (isNaN(_value)) ? "~" : _value;
                // re-caulutate sell price & profit margin if not flagged
                if (_value !== "~") {
                    let new_profit_margin = calculateProfitMargin(_order.sell_price, _order.supplier_price, _value, _order.exchange);
                    _order.profit_margin = new_profit_margin;
                }
                break;
        }

        if (_order) {
            if (_value !== "~") {
                _order[_field] = _value;
            }
        }

        let m = [...selectedSuppliers];
        m.splice(_index, 1, _order)

        // If user edits the blank, add a new key
        if (_order.key === defaultKey) {
            let add_key = makeKey();
            setDefaultKey(add_key);
            m.push(createNewSupplier(add_key))
        }

        setSelectedSuppliers(m);

        if (_save) {
            saveOrder(true, m);
        }
        return;
        return _value;
    }

    const addNewBlank = () => {
        return;
    }

    const incQuantity = (_key, _amt) => {
        let _order = selectedSuppliers.find(item => item.key === _key);
        if (_order.quantity === "") {
            _order.quantity = 0;
        }
        let _value = Math.min(Math.max(parseInt(_order.quantity) + parseInt(_amt), 0), 999);
        updateOrder(_key, "quantity", _value);
    }

    const clearOrder = (_key) => {
        let m = [...selectedSuppliers];
        m = m.filter(item => item.key != _key)
        if (m.length === 0) {
            let _default = [createNewSupplier(makeKey())];
            setSelectedSuppliers(_default);
            saveOrder(true, _default);
        } else {
            setSelectedSuppliers(m.filter(item => item.key != _key))
            saveOrder(true, m.filter(item => item.key != _key));
        }
        setExpanded([]);
    }

    const addBlankRow = () => {
        let m = [...selectedSuppliers];
        m.push(createNewSupplier(makeKey()))
        setSelectedSuppliers(m);
    }

    //Supl, Grp, Qty, Sell, PM, SuppPr, Disc, QP-Grp

    const drawGroupLabel = (_item, _f) => {
        // No customer
        if (!_f.customer_name) {
            return _item;
        }
        // Has customer, but not a store
        if (!isNumeric(_item)) {
            return _item;
        }
        // Has customer, but is current store
        if (isNumeric(_item) && _item == storeNum) {
            return _item;
        }
        return (<div onClick={(e) => e.stopPropagation()} style={{ "opacity": "0.5", "cursor": "not-allowed" }}>{_item}</div>)
    }

    const [orderBtn, setOrderBtn] = useState(true);


    const drawNotesLabel = (_f) => {
        if (_f.customer_name || _f.customer_po || _f.order_note) {
            return (<><u>Notes</u></>)
        } else {
            return (<>Notes</>)
        }
    }

    const columns = [
        {
            title: <small>Supp</small>, width: "55px", key: 'supplier', dataIndex: 'supplier', render: (e, f) => {

                if ((!wrap) && orderBtn && (!e)) {
                    return (<Tooltip title={<small>Only use this to create orders for non-Pubstock vendors that do not appear in the list below. Otherwise, click a vendor from the list below to create orders.</small>}><Tag onClick={() => setOrderBtn(false)} style={{ "lineHeight": "15px", "cursor": "pointer", "margin": "0px 0px" }} color={"#333"} size="small"><small>Order</small></Tag></Tooltip>)
                }
                return <div style={{ "padding": "2px 0px", "paddingRight": "0px" }}><Input maxLength={5} placeholder="Supp" onFocus={(e) => e.target.select()} onBlur={(e) => {

                    if (e.target.value.length > 2 && e.target.value.length < 6) {
                        let sup = e.target.value
                        if (e.target.value.length === 5) {
                            sup = sup.substring(0, 3);
                        }

                        setPrefs({ ...prefs, "last_used": e.target.value })
                    }
                    saveOrder()

                }} onChange={(_e) => {

                    if (_e.target.value.length > 0 && Cookies.get("pin_splitter") === "yes") {
                        setExpanded([f.key]);
                    }
                    updateOrder(f.key, "supplier", _e.target.value.toUpperCase())

                }} className="tinyInput" value={e} size="small"></Input></div>;
            }
        },

        // {
        //     title: <small>Grp</small>, key: 'group', width: "40px", dataIndex: 'group', render: (e, f) => {

        //         return <div style={{ "padding": "2px 0px", "paddingRight": "0px" }}><Select onBlur={() => saveOrder()} disabled={!(f.supplier)} onChange={(_g) => updateOrder(f.key, "group", _g)} value={(f.supplier) ? e : ""} style={{ "fontSize": "11px", "width": "35px" }} showArrow={false} className="tinyAutocomplete" dropdownClassName="tinyDropdown">
        //             {getGroups().map(item => {
        //                 return <Select.Option key={item} value={item}>{item}</Select.Option>
        //             })}
        //         </Select></div>
        //     }
        // },

        {
            title: <small>Grp</small>, key: 'group', width: "40px", dataIndex: 'group', render: (e, f) => {

                return (<GroupPicker disabled={!(f.supplier)} value={e} onChange={(_v) => updateOrder(f.key, "group", _v, true)} />)

                return <div style={{ "padding": "2px 0px", "paddingRight": "0px" }}><AutoComplete
                    value={(f.supplier) ? e : ""} //
                    onBlur={() => saveOrder()} //
                    onFocus={(e) => e.target.select()}
                    // autoFocus={(selectedKey === f.key)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.currentTarget.blur();
                        }
                    }}
                    disabled={!(f.supplier)}
                    onChange={(_g) => {

                        if (getGroups().includes(_g.toUpperCase())) {
                            updateOrder(f.key, "group", _g.toUpperCase())
                        }

                    }} //
                    style={{ width: "30px", "fontSize": "11px", "height": "23px", "marginTop": "3px" }}
                    className={"tinyAutocomplete"}
                    dropdownClassName="tinyDropdown"
                    size="small"
                    options={getGroups().map(item => {
                        return {
                            value: item,
                            label: drawGroupLabel(item, f),

                        }
                    })}
                /></div>

                return <Input onBlur={() => addNewBlank()} onChange={(_e) => updateOrder(f.key, "quantity", _e.target.value)} className="tinyInput" value={e} size="small"></Input>;
            }
        },

        {
            title: <small>Qty</small>, key: 'quantity', width: "40px", dataIndex: 'quantity', render: (e, f) => {

                return <div style={{ "padding": "2px 0px", "paddingRight": "0px" }}>

                    
                    <NumPicker disabled={!(f.supplier)} autoFocus={(selectedKey === f.key)} value={e} onChange={(_v) => { setQuantityInput(!quantityInput); updateOrder(f.key, "quantity", _v, true); }} />
                    {/* <AutoComplete
                    value={e}
                    onBlur={() => saveOrder(true, selectedSuppliers, true)}
                    onFocus={(e) => e.target.select()}
                    autoFocus={(selectedKey === f.key)}
                    onKeyDown={(e) => {

                        if (e.key === "Enter") {
                            updateOrder(f.key, "quantity", e.target.value)
                            e.currentTarget.blur();
                        }

                    }}
                    disabled={!(f.supplier)}
                    onChange={(_e) => updateOrder(f.key, "quantity", _e)}
                    style={{ width: "30px", "fontSize": "11px", "height": "23px", "marginTop": "3px" }}
                    className={(f.supplier && (e > 0)) ? "tinyAutocomplete highlightInput" : "tinyAutocomplete"}
                    dropdownClassName="tinyDropdown"
                    size="small"
                    options={[{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }, { value: 11 }, { value: 12 }]}
                /> */}

                </div>

                return <Input onBlur={() => addNewBlank()} onChange={(_e) => updateOrder(f.key, "quantity", _e.target.value)} className="tinyInput" value={e} size="small"></Input>;
            }
        },

        {
            title: <small>Sell</small>, width: "55px", key: 'sell_price', dataIndex: 'sell_price', render: (e, f) => {

                return <AutoComplete
                    value={e}
                    disabled={!(f.supplier)}
                    onBlur={() => saveOrder()}
                    onFocus={(e) => e.target.select()}
                    onChange={(_e) => updatePrice(_e)}
                    style={{ width: "45px", "fontSize": "11px", "textAlign": "left" }}
                    className="tinyAutocomplete"
                    dropdownClassName="tinyDropdown"
                    size="small"
                    options={
                        getMemory("sell_price").map(val => {
                            return { value: val }
                        })}
                />


                return <Input placeholder="0.00" onChange={(_e) => updatePrice(_e.target.value)} className="tinyInput" value={e} size="small"></Input>;
            }
        },

        {
            title: <small>PM</small>, key: 'profit_margin', width: "35px", dataIndex: 'profit_margin', render: (e, f) => {

                if (!f.supplier) {
                    return <div style={{ "padding": "2px 2px", "paddingRight": "0px", "color": "#aaa" }}>0%</div>
                }
                return <div style={{ "padding": "2px 2px", "paddingRight": "0px" }}>{(e) ? <>{e}%</> : ""}</div>;
            }
        },



        {
            title: <small>SupPr</small>, key: 'supplier_price', width: "55px", dataIndex: 'supplier_price', render: (e, f) => {



                return <div style={{ "padding": "2px 0px", "paddingRight": "0px" }}><AutoComplete
                    value={e}
                    disabled={!(f.supplier)}
                    style={{ width: "45px", "fontSize": "11px", "lineHeight": "10px" }}
                    onBlur={() => saveOrder()}
                    onFocus={(e) => e.target.select()}
                    onChange={(_e) => updateOrder(f.key, "supplier_price", _e)}
                    className="tinyAutocomplete"
                    dropdownClassName="tinyDropdown"
                    size="small"
                    options={getMemory("sell_price").map(val => {
                        return { value: val }
                    })}
                /></div>


            }
        },


        {
            title: <small>Disc</small>, key: 'discount', width: "45px", dataIndex: 'discount', render: (e, f) => {
                return <Input disabled={!(f.supplier)} onFocus={(e) => e.target.select()} onChange={(_e) => {
                    updateOrder(f.key, "discount", _e.target.value)
                }} className="tinyInput" value={e} size="small"></Input>;
            }
        },
        // {
        //     title: <small>Split</small>, key: 'split', dataIndex: 'split', render: (e, f) => {
        //         return <><Button onClick={() => expandCell(f, "splitter")} size="small" type="link"><BranchesOutlined /></Button></>;
        //     }
        // },
        // {
        //     title: <small>Notes</small>, key: 'notes', dataIndex: 'notes', render: (e, f) => {
        //         return <><Button onClick={() => expandCell(f, "notes")} size="small" type="link"><FormOutlined /></Button></>;
        //     }
        // },
        {
            title: <small></small>, key: 'key', dataIndex: 'key', render: (e, f) => {
                return <Button disabled={(selectedSuppliers.length === 1 && f.supplier === "")} size="small" type="link" danger onClick={(_e) => {
                    clearOrder(e)
                    _e.preventDefault();
                    _e.stopPropagation();

                }} icon={<CloseCircleFilled style={{ "fontSize": "12px" }} />} />
            }
        },

        {
            title: <div style={{ "textAlign": "right", "paddingRight": "13px" }}>
                {((selectedSuppliers.length > 0 && selectedSuppliers[selectedSuppliers.length - 1].supplier !== "") ?
                    <small onClick={() => addBlankRow()}><a>+ New</a></small> : <small>+ New</small>
                )}
            </div>, width: "105px", key: 'add_row', dataIndex: 'add_row', render: (e, f, i) => {

                if (!f.supplier) {
                    return (<></>)
                }
                return (<div style={{ "textAlign": "right" }}><Button type="link" onClick={() => expandCell(f)} className="tiny-btn" size="small"><small>{drawNotesLabel(f)}{((f.stores.filter(item => item.cart_active || item.is_primary).length > 1) && " / Splitter")} {(expanded.includes(f.key) ? <CaretUpOutlined style={{ "fontSize": "10px" }} /> : <CaretDownOutlined style={{ "fontSize": "10px" }} />)}</small></Button>&nbsp;&nbsp;</div>)
                // return (i === (selectedSuppliers.length - 1)) ? <div style={{ "float": "right", "paddingRight": "10px" }}><Button onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOrderBtn(true); addBlankRow() }} disabled={!(selectedSuppliers.length > 0 && selectedSuppliers[selectedSuppliers.length - 1].supplier !== "")} size="small" className="tiny-btn" type="link"><small>Add row</small></Button></div> : "";
            }
        },

        {
            title: <small>QP-Grp</small>, key: 'quickpick', width: "66px", dataIndex: 'quickpick', render: (e, f) => {
                return <div style={{ "height": "38px" }}><QuickPick supplier={f.supplier} /></div>
            }
        },

    ]

    const calculateSellPrice = (_publish_price, _discount, _profit_margin, _exchange) => {
        if (_discount == 'BX' || _discount == 'RG' || _discount == '') {
            _discount = 40;
        }
        if (_discount == 'NT') {
            _discount = 0;
        }
        _discount -= 0;
        var sellp = - (_exchange * _publish_price * ((100 - _discount) / 100)) / ((_profit_margin / 100) - 1);
        if (isNaN(sellp)) {
            return '';
        }
        return sellp.toFixed(2);
    }

    const calculateProfitMargin = (_sell_price, _publish_price, _discount, _exchange) => {
        if (_discount == 'BX' || _discount == 'RG' || _discount == '') {
            _discount = 40;
        }
        if (_discount == 'NT') {
            _discount = 0;
        }
        _discount -= 0;
        _sell_price -= 0;
        if (!_sell_price) {
            return '';
        }
        var pm = 1 - ((_exchange * _publish_price * ((100 - _discount) / 100)) / _sell_price);
        pm = Math.round(pm * 100);
        if (isNaN(pm)) {
            pm = '';
        }
        // 100 is impossible, max should be 99
        if (pm === 100) {
            pm = 99;
        }
        return pm;
    }


    const expandCell = (_data) => {

        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }

    }



    const drawChildTable = (_record) => {


        return (
            <div>
                <conditional.true value={(window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).length > 1 && _record.supplier)}>
                    <div style={{ "padding": "10px 10px", "paddingBottom": "0px", "marginTop": "2px" }}>
                        {((window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).length > 1) && <Splitter quantityInput={quantityInput} rerender={rerender} saveSplitter={saveSplitter} updateOrder={updateOrder} record={_record} />)}
                    </div>
                </conditional.true>


                <div className="hideHover bc" style={{ "margin": "0px 0px", "marginLeft": "-9px", "paddingLeft": "8px", "borderLeft": "1px solid", "marginTop": "0px", "height": (expanded.length > 0 && expanded[0] === _record.key) ? "auto" : "0px", "overflowY": "hidden" }}>
                    <Button style={{ "float": "right", "marginTop": "0px", "marginRight": "5px", "position": "absolute", "right": "-3px", "position": "relative", "zIndex": "1" }} onClick={() => setExpanded([])} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />

                    <div style={{ "padding": (window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).length > 1) ? "10px" : "10px 0px", "paddingTop": (window.sitesettings.stores.filter(item => item.cart_active || item.is_primary).length > 1) ? "0px" : "10px" }}>
                        <Row gutter={20}>
                            <Col span={9}>
                                <div style={{ "textAlign": "left", "fontSize": "10px", "color": "#888", "marginLeft": "-10px" }}>Customer name</div>
                                <div style={{ "marginLeft": "-10px" }}><OrderSearchUser disabled={(isNumeric(_record.group) && _record.group != storeNum)} recordKey={_record.key} updateOrder={updateOrder} placeholder="Customer name..." defaultValue={_record.customer_name} /></div>
                                {/* <Input defaultValue={_record.customer_name} onChange={(e) => updateOrder(_record.key, "customer_name", e.target.value)} placeholder="Customer name..." style={{ "fontSize": "12px" }} size="small"></Input> */}
                                <div className="shim" />
                                <div style={{ "textAlign": "left", "fontSize": "10px", "color": "#888", "marginLeft": "-10px" }}>Customer PO#</div>
                                <div style={{ "marginLeft": "-10px" }}>

                                    <AutoComplete options={getMemory("customer_po").map((item, index) => {
                                        return { value: item }
                                    })}

                                        onSelect={(e) => {
                                            updateOrder(_record.key, "customer_po", e);
                                            saveOrder();
                                        }}
                                        onFocus={(e) => e.target.select()} onBlur={(e) => {
                                            updateOrder(_record.key, "customer_po", e.target.value);
                                            if (e.target.value) {
                                                saveMemory("customer_po", e.target.value);
                                            }
                                            saveOrder();
                                        }} defaultValue={_record.customer_po} onChange={(e) => {
                                            updateOrder(_record.key, "customer_po", e)
                                        }} placeholder="PO#..." style={{ "fontSize": "12px", "width": "100%" }} size="small" />

                                    {/* <Input onFocus={(e) => e.target.select()} onBlur={() => saveOrder()} defaultValue={_record.customer_po} onChange={(e) => updateOrder(_record.key, "customer_po", e.target.value)} placeholder="PO#..." style={{ "fontSize": "12px" }} size="small"></Input> */}

                                </div>
                            </Col>
                            <Col span={15}>
                                <RememberInput updateOrder={updateOrder} saveOrder={saveOrder} record={_record} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        )

    }

    const saveCategoriesNotes = (_notes = note, _category = category, _subcategory = subCategory, skip_note = false, guess = false) => {

        if (skip_note) {
            _notes = note;
        }

        let obj = {};
        obj.isbn = isbn;

        if (_category) {

            if (obj.notes !== null) {
                obj.notes = _notes;
            }

            obj.class_1 = _category;

            if (obj.class_2 !== null) {
                obj.class_2 = _subcategory;
            }

        }

        if (!_category) {
            obj.notes = "";
            obj.class_1 = ""
            obj.class_2 = ""
        }

        obj.exact_match = guess;
        obj.condition_code = condition;

        apiCall("title/saveCategoriesNotes", obj, (_status, _result) => {
            if (_status) {

            } else {
                message.error(_result.error);
            }
        })
    }


    const getColorBar = () => {


        let _bc = "5px solid #eee"
        if (Cookies.get("darkmode") === "true") {
            _bc = "5px solid #444"
        }

        if (loading) {
            return _bc;
        }

        let count = selectedSuppliers.filter(item => item.supplier);

        if (count.length > 0) {
            return "5px solid #3C8617";
        } else {

            return _bc;
        }


    }


    const RememberCats = () => {

        const callback = (_code, _category, _subcategory, _notes) => {
            let _a = [...data.conditions];
            let _f = _a.findIndex(item => item.code === _code);
            if(_f > -1){
                _a[_f].category = _category;
                _a[_f].subcategory = _subcategory;
                _a[_f].notes = _notes;
                setResults({...data, "conditions" : _a});
            }
        }

        return (
            <>
                {data.conditions.map(condition => {    
                    return (
                        <div>
                            <div><small>{condition.code_text}:</small></div>
                            <div style={{ "border": (condition.total_onhand > 0) ? "1px solid" : "none" }} className="bc" ><BMNoteCats callback={callback} store={prefs.store} total_onhand={condition.total_onhand} suggested_bm_data={condition.suggested_bm_data} isbn={isbn} series={""} condition_code={condition.code} condition={condition.code_text} data={condition} /></div>
                            <div className="shim" />
                        </div>
                    )
                })}
            </>
        )
    }


    const draw = () => {




        return (
            <div style={{ "borderLeft": getColorBar(), transitionProperty: "border", "transitionDuration": "0.2s", "marginLeft": "-5px" }}>

                <div className="bc" style={{ "border": "1px solid", "borderBottom": "none" }}>


                    <Table
                        size="small"
                        columns={columns}
                        dataSource={selectedSuppliers}
                        pagination={false}
                        title={null}
                        className={"pubstockTable"}
                        expandable
                        rowClassName={(record, index) => (record.key === selectedKey) ? "selectedRow" : ""}
                        expandedRowKeys={selectedSuppliers.map(item => item.key)}
                        expandIconColumnIndex={-1}
                        expandIcon={() => { return (<></>) }}
                        expandedRowRender={(record) => drawChildTable(record)}
                    />
                </div>
                {(wrap && <><div className="shim" /><div className="shim" /><div className="shim" /></>)}
                <div className="bc" style={{ "border": "1px solid", "borderBottom": "none", "borderTop": (wrap) ? "1px solid" : "none" }}>
                    <Pubstock title_suppliers={data.suppliers} want_store={false} wrap={wrap} price={price} list_price_can={list_price_can} list_price_usa={list_price_usa} class="pubstockTable" updatePrice={updatePrice} selectSupplier={selectSupplier} is_order={true} isbn={isbn} />
                </div>
            </div>
        )
    }


    if (wrap) {
        return (
            <div style={{ "padding": "20px" }}>
                <Typography.Title level={5}>Order: {(data.title) ? data.title : ""} <small> ({isbn})</small></Typography.Title>
                <Divider style={{ "marginTop": "15px" }} />

                <RememberCats />
                <Divider style={{ "margin": "15px 0px" }} dashed />
                <div><small>Cart:</small></div>
                {draw()}
                <div className="shim" /><div className="shim" />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={() => close()}>Close</Button>
                    </Space>
                </div>
                <br clear="all" />

            </div>
        )
    }


    return draw();

}