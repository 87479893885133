import { CloseOutlined, DeleteOutlined, SlidersOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Input, message, Modal, Popconfirm, Row, Space, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { apiCall } from "../utils/Api";
import { useSession } from "../utils/Session";
import { bmDate, objectToPath, pathToObject, successColor } from "../utils/Utils";

//     Toolbox/Result values: {
//          "selections": [],
//          "header": "status",
//          "version": "v3",
//          "id": 3,
//          "name": "test",
//          "is_shared": false,
//          "is_yours": true,
//          "last_used": 1649284134,
//          "creator": "Michael Hunter"
//      }

export default function FilterPresets(props) {

    const { header = "status", searchFilters = {}, showselected=false, setSearchFilters= () => {}, getBrowse=() => {}, path = "", toolboxes = { store_specific: [], bookmanager: [] } } = props;
    const [session, setSession] = useSession();
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState("")
    const [results, setResults] = useState(toolboxes);
    const history = useHistory();

    const routePath = (_arr) => {

 
        



        apiCall("toolbox/pingUsed", {id : _arr.id}, () => {});
        
        if(_arr.selections.hasOwnProperty("p")){
            delete _arr.selections.p; 
        }

       
        if(searchFilters?.l){
            _arr.selections.l = searchFilters.l; 
        }
        
        let filters = objectToPath(_arr.selections);
        setSearchFilters(_arr.selections);
        getBrowse(false, _arr.selections);

        history.push(path + filters + "/p/" + _arr.id);
        setVisible(false);
    }


    const sharePreset = (_id, _shared = true) => {
        apiCall("toolbox/setShared", { id: _id, header: header, shared: _shared }, (_status, _result) => {
            if (_status) {
                setResults(_result);
                message.success((_shared) ? "Preset shared" : "Preset un-shared");
            } else {
                message.error("There was a problem sharing the preset.")
            }
        })
    }

    
    const deletePreset = (_id) => {
        apiCall("toolbox/delete", { id: _id, header: header }, (_status, _result) => {
            if (_status) {
                message.success("Preset deleted.");
                setResults(_result);
            } else {
                if(_result.code === 1){
                    Modal.error({
                        title : "Preset currently in use:",
                        content : <>
                            {((_result.data.hasOwnProperty("shipping")) && <>&bull; Source for shipping provider</>)}
                            {((_result.data.hasOwnProperty("titlelist_def")) && <>&bull; Source for list: ({_result.data.titlelist_def.join(", ")})</>)}
                            <div><small>You cannot delete the preset until you unlink</small></div>
                        </>
                    })
                } else {
                    message.error("There was a problem deleting the preset.")
                }
            }
        })
    }

    const saveCurrent = (_name) => {
        let _sf = {...searchFilters};
        if(_sf["x"] && !Array.isArray(_sf["x"])){
            _sf["x"] = [_sf["x"]]
        }
        delete _sf.l;
        delete _sf.o;
        let obj = {
            name: _name,
            header: header,
            version: "v3",
            selections_json: JSON.stringify(_sf)
        }

        apiCall("toolbox/create", obj, (_status, _result) => {
            if (_status) {
                setResults(_result);
                setName("");
            } else {
                message.error(_result.error);
            }
        })
    }

    const drawDefault = (_obj) => {
        if (_obj.id === session.filter_preset_defaults[header].id) {
            return <StarFilled style={{ "color": "#eabf4b", "cursor": "pointer" }} />
        } else {
            return <Tooltip title={<small>Set as default.</small>}><StarOutlined style={{ "color": "#AAA", "cursor": "pointer" }} /></Tooltip>
        }
    }

    const setDefault = (_id) => {
        apiCall("toolbox/setDefault", { header: header, id: _id }, (_status, _result) => {
            setSession({ ...session, "filter_preset_defaults": _result });
        })
    }

    const columns = [
        {
            title: <small>Name</small>, bm: false, className: "nowrap150", dataIndex: 'name', key: 'name', render: (e, f) => {
                return (
                    <>
                        <div><a onClick={() => routePath(f)}>{e}</a></div>
                    </>
                )
            }
        },
        {
            title: <small>Creator</small>, bm: true, className: "nowrap80", dataIndex: 'creator', key: 'creator', render: (e) => {
                return (e) ? <span style={{ "fontSize": "11px" }}>{e}</span> : ""
            }
        },
        {
            title: <small>Last used</small>, bm: true, width: "65px", dataIndex: 'last_used', key: 'last_used', render: (e) => {
                return (e) ? <span style={{ "fontSize": "11px" }}>{bmDate(e * 1000)}</span> : ""
            }
        },
        {
            title: <small></small>, bm: false, width: "0px", dataIndex: 'actions', key: 'actions', render: (e, f) => {

                if (f.creator === "Bookmanager") {
                    return <div style={{ "paddingRight": "8px" }} onClick={() => setDefault(f.id)}>{drawDefault(f)}</div>;
                }
                return <>
                    <Space size={4}>
                        <div style={{ "paddingRight": "8px" }} onClick={() => setDefault(f.id)}>{drawDefault(f)}</div>
                        {/* <Popconfirm disabled={(!f.is_yours)} onConfirm={() => sharePreset(f.id, (!f.is_shared))} title="Share?"><Button disabled={(!f.is_yours)} style={{ "opacity": (!f.is_yours) ? "0" : "1", "padding": "0px 6px" }} type="primary" size="small" className="tiny-btn"><small>{(f.is_shared) ? "Un-share" : "Share"}</small></Button></Popconfirm> */}
                        <Popconfirm disabled={(!f.is_yours)} onConfirm={() => deletePreset(f.id)} title="Delete?"><Button disabled={(!f.is_yours)} danger type="primary" size="small" className="tiny-btn"><DeleteOutlined /></Button></Popconfirm>
                    </Space>
                </>
            }
        },
    ];


    const drawTable = (_label, _data, _show_create, _show_tools = true) => {
        return (
            <>
                <Typography.Title level={5}>

                    {(_show_create && <>&nbsp;<>
                        <div style={{ "width": "250px", "float": "right" }}><Input.Search maxLength={50} onSearch={(e) => saveCurrent(e)} value={name} onChange={(e) => setName(e.target.value)} enterButton={<small>Save</small>} placeholder="Create new preset..." size="small" /></div>
                    </>
                    </>)}
                    <small>{_label}</small>
                </Typography.Title>
                <Table pagination={false} className="presetTable" columns={(_label === "Bookmanager") ? columns.filter(item => !item.bm) : columns} dataSource={_data} />
            </>
        )
    }

    const drawPresets = () => {
        return (
            <div style={{ "marginTop": "85px" }}>
                <Typography.Title level={5}>Filter presets</Typography.Title>
                <Divider style={{ "margin": "15px 0px", "marginBottom" : "25px" }} dashed />
                <Row gutter={25}>
                    <Col span={12}>
                        {drawTable((session.contact.first_name) ? session.contact.first_name + "'s presets" : "My presets", results.store_specific.filter(item => item.is_yours), true)}
                        <Divider style={{"marginBottom" : "10px"}} dashed />
                        {drawTable("Bookmanager", results.bookmanager, false, false)}
                    </Col>
                    <Col span={12}>
                        {drawTable("Shared with staff at " + session.store_name, results.store_specific.filter(item => !item.is_yours))}
                    </Col>
                </Row>
            </div>
        )
    }

    return (<><Drawer closable={true} closeIcon={<div style={{ "marginTop": "85px" }}><CloseOutlined /></div>} width={1240} placement="left" onClose={() => setVisible(false)} visible={visible}>{drawPresets()}</Drawer><Button onClick={(e) => setVisible(true)} icon={<small><SlidersOutlined style={{ "marginRight": "4px", "marginLeft": "-3px" }} /></small>} className="filterpreset" style={{ "padding": "0px 10px", "lineHeight": "10px", "height": "23px", "border": "none" }} type="primary"><span style={{ "fontSize": "12px" }}>Filter presets</span></Button></>)
}