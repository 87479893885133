import React, { useEffect, useState } from "react";
import Sales from "./Sales";
import AnalyticsTable from "./AnaylticsTable";
import AnalyticsDetails from "./AnalyticsDetails";
import OnHand from "./OnHand";
import Received from "./Received";
import { Space } from "antd";
import moment from "moment-timezone";

export default function Histogram(props) {

    const { single = false, includes = {}, selected = {}, resetScale, setSelected = () => {}, record = {}, metadata = {}, getSet = () => { }, list = false, filters = {}, details = {}, grouping = "", searchFilters = {}, parseSearchFilters = () => { }, toggleView = () => { } } = props;
    const { segment_labels = {} } = metadata;
    const { sales = [], salesrtrn = [], onhand = [] } = segment_labels;
    const [drilldown, setDrilldown] = useState("");


    const scale = (props.scale === record.id);

    const onSetDrilldown = (_drilldown, _id) => {

    
      
        if(!list && _id === selected.id){
            
            setDrilldown(_drilldown);
            props.setDrilldown(_drilldown);
        } 

        if(list){
            setDrilldown(_drilldown);
        }

        
    }

    useEffect(() => {

        props.setDrilldown(drilldown);

    }, [drilldown])

    const findLabel = (_key, _value) => {
        // Remove the fist char if it's a dash - (excluded)
        if (_value[0] === '-') {
            _value = _value.slice(1);
        }

        let ret = metadata.seen[_key].find(item => item.v === _value);
        if (ret) {

            if (ret.hasOwnProperty("e")) {
                return ret.e;
            } else {
                return _value;
            }
        } else {
            return _value;
        }
    }

    // const drawDateOrGroup = (_token = record.grouptokens) => {

    //     let _label = _token.filter(item => item.grouping.length > 0).map(item => {
    //         return (<div style={{ "color": "#177ddc" }}><span>{item.grouping.map(i => findLabel(item.group, i)).join(", ")}</span></div>);
    //     })

    //     if (_label.length) {
    //         return (
    //             <div>
    //                 {(_label.length > 0 && <div><Space>{_label}</Space></div>)}
    //             </div>
    //         )
    //     } else {
    //         return(<div>{moment(record.slice_datehigh * 1000).format("MMMD-YY")}</div>)
    //     }
    // }

    const drawDateOrGroup = (_token = []) => {


        
        let _label = _token.filter(item => item.grouping.length > 0).map(item => {
            return (<div style={{ "color": "#177ddc" }}><span>{item.grouping.map(i => findLabel(item.group, i)).join(", ")}</span></div>);
        })

        let _includes = _token.filter(item => item.includes.length > 0).map(item => {
            return (<div style={{ "color": "green" }}><span>{item.includes.map(i => findLabel(item.group, i)).join(", ")}</span></div>)
        })

        let _excludes = _token.filter(item => item.excludes.length > 0).map(item => {
            return (<div style={{ "color": "#c52738" }}><span>{item.excludes.map(i => findLabel(item.group, i)).join(", ")}</span></div>)
        })

  
        if (_label.length) {
            return (
                <Space>
                    {/* <div>{moment(record.slice_datehigh * 1000).format("MMMD-YY")}</div> */}
                    {(_label.length > 0 && <div><Space>{_label}</Space></div>)}
                    {/* {(_includes.length > 0 && <div><Space>{_includes}</Space></div>)}
                    {(_excludes.length > 0 && <div><Space>{_excludes}</Space></div>)} */}
                </Space>
            )
        } else {

            return(<>{moment(record.slice_datehigh * 1000).format("MMMD-YY")}</>)
        }



    }


    const is_selected = (selected?.id === record?.id); 
    return (
        <>
            {(!list && <div className="histogram-label"><small>{drawDateOrGroup(record.grouptokens)}</small></div>)}
            
            <div onClick={() => {
                if(!list){
                    setSelected(record);
                }
            }} className={(list) ? "" : (selected?.id !== record?.id) ? "histogram-bg": (selected?.id === resetScale) ? "histogram-bg-enlarged" : "histogram-bg-selected"} style={{ "display": "flex", "flexDirection": (list) ? "column" : "row", "gap": "2px", "padding": (list) ? "0px" : "10px 20px 0px 20px", "height" : (list) ? "auto" : "411px" }}>
                {(includes.sales && <Sales setDrilldown={onSetDrilldown} filters={filters} scale={scale} list={list} getSet={getSet} record={record} sales={sales} salesrtrn={salesrtrn} />)}
                {(includes.inventory && <OnHand showOnOrder={!includes.receiving_returns} setDrilldown={onSetDrilldown} filters={filters} scale={scale} list={list} getSet={getSet} record={record} onhand={onhand} />)}
                {(includes.receiving_returns && <Received setDrilldown={onSetDrilldown} filters={filters} scale={scale} list={list} getSet={getSet} record={record} />)}
            </div>
            {(list && <>
                <div style={{ "height": "10px" }} className="shim" />
                {(includes.details && <AnalyticsDetails list={list} single={single} filters={filters} data={record} />)}
                {(drilldown &&
                    <AnalyticsTable metadata={metadata} grouptokens={record.grouptokens} parseSearchFilters={parseSearchFilters} searchFilters={searchFilters} grouping={grouping} item={record} setView={setDrilldown} drilldown={drilldown} filters={filters} />
                )}
            </>)}
            {((is_selected && !list && !single) && <div style={{"backgroundColor" : (resetScale === selected?.id) ? "#a61d2444" : "#177ddc44", "height" : "10px", "clipPath": "polygon(0 0, 50% 100%, 100% 0)"}}></div>)}
        </>
    )
}