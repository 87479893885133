import { CaretDownOutlined, CaretUpOutlined, CopyOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Checkbox, Col, DatePicker, Divider, Dropdown, Menu, Popconfirm, Radio, Select, Space, Statistic, Table, Tabs, Tag, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import GroupPicker from '../tools/GroupPicker.js';
import { apiCall } from "../utils/Api";
import conditional from "../utils/conditional.js";
import { usePrefs } from "../utils/Prefs";
import { useSession } from "../utils/Session";
import { getMemory, groupSort } from "../utils/Utils";
import { drawDeliveryTime } from "../utils/Utils";
import BusinessContactInfo from "./BusinessContactInfo";
import Overlay from "./Overlay";
import QuickPick from "./QuickPick";


export const drawOO = (_code) =>{
    let msg = ""
    switch (_code) {

        case "ITO":
            msg = <>Import To Order<small><br />Must be imported, taking at least 2-6 weeks to get stock.</small></>
            break;

        case "Qwk":
            msg = <>Quick<small><br />Not stocked, but can get stock in less than 2 weeks.</small></>
            break;

        case "LOW":
            msg = <>LOW<small><br />Stock onhand is relatively low. Item may get backordered/cancelled.</small></>
            break;

        case "POD":
            msg = <>Print On Demand<small><br />Copies printed on demand.</small></>
            break;

        case "QPOD":
            msg = <>Quick Print On Demand<small><br />Copies printed on demand the same day.</small></>
            break;

        case "RP":
            msg = <>Reprinting<small><br />The book is being reprinted by the publisher.</small></>
            break;

        case "NYR":
            msg = <>Not Yet Received / Published<small><br />Not received by the wholesaler or published by the publisher.</small></>
            break;

        case "TOS":
            msg = <>Temporarily out of stock<small><br />Item will be in stock shortly.</small></>
            break;

        case "BOO":
            msg = <>Backorder Only<small><br />Title available as a special order. ETA unknown.</small></>
            break;

        case "Mgd":
            msg = <>Managed Title<small><br />Quantities shipped are managed manually.</small></>
            break;

    }
    return msg; 
}

export const drawOOToolTip = (_str) => {

    let _arr = [
        "ITO - Import To Order: must be imported, taking at least 2-6 weeks to get stock.",
        "Qwk - Quick: not stocked, but can get stock in less than 2 weeks.",
        "POD - Print On Demand: copies printed on demand.",
        "QPOD - Quick Print On Demand: copies printed on demand the same day.",
        "RP - Reprinting: the book is being reprinted by the publisher.",
        "NYR - Not Yet Received/Published: not yet received by the wholesaler, or published by the publisher.",
        "TOS - Temporarily out of stock: item will be in stock shortly.",
        "BOO - 'Backorder Only' title available as special order. ETA unknown.",
        "Mgd - Managed title: quantities shipped are managed manually"
    ]

    return (
        <Tooltip overlayInnerStyle={{ width: "550px" }} title={<>
            <div>Non-numeric OO pubstock codes.</div>
            <div className="shim" />
            <div>
                <small>
                    {_arr.map(item => {
                        return (<div>{item}</div>)
                    })}
                </small>
            </div>
        </>}>
            <span style={{ "cursor": "pointer" }}>{_str}</span>
        </Tooltip>
    )
}

export default function Pubstock(props) {

    const { want_store = true, wrap = false, title_suppliers = false, updatePrice = () => { }, isbn, is_order = false, selectSupplier, drawComponent = () => { }, price = "", list_price_can = "", list_price_usa = "", show_stores = false } = props;

    const [session, setSession] = useSession();
    const [suppliers, setSuppliers] = useState((title_suppliers) ? title_suppliers : []);
    const [bm, setBM] = useState({ class_1: "" });
    const [stores, setStores] = useState([]);
    const [prefs, setPrefs] = usePrefs();
    const groups = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    if (!show_stores) {
        moment.updateLocale('en', {
            relativeTime: {
                future: "in %s",
                past: "%s ",
                s: "a sec ago",
                m: "a min ago",
                mm: "%d min ago",
                h: "an hour ago",
                hh: "%d hours ago",
                d: "a day ago",
                dd: "%d days ago",
                M: "a month ago",
                MM: "%d months ago",
                y: "a year ago",
                yy: "%d years ago"
            }
        });
    } else {
        moment.updateLocale('en', {
            relativeTime: {
                future: "+%s",
                past: "%s",
                s: "1s",
                m: "1m",
                mm: "%dm",
                h: "1h",
                hh: "%dh",
                d: "1d",
                dd: "%dd",
                M: "1m",
                MM: "%dm",
                y: "1y",
                yy: "%dy"
            }
        });

    }




    ///i/nocover-120.png
    const getPubstock = () => {

        if (title_suppliers) {
            return;
        }

        apiCall("title/getPubstock", { isbn: isbn, want_store: want_store, want_supplier: true, want_bm_data: true }, (_status, _result) => {
            if (_status) {
                setSuppliers(addKeys(_result.suppliers));
                setStores(addKeys(_result.stores));
            }
        })
    }

    useEffect(getPubstock, [isbn]);


    const ColumnHelp = (props) => {

        return (
            <Tooltip title={props.title}>
                <span style={{ "cursor": "pointer" }} >{props.children}</span>
            </Tooltip>
        )
    }

    
   

    const drawOOMsg = (_code) => {

        let msg = drawOO(_code);
        
        if (msg) {
            return (<ColumnHelp title={msg}>{_code}</ColumnHelp>)
        }

        return _code;

    }


    const suppliers_columns = [

        {
            title: <small></small>, key: 'flag', width: "30px", dataIndex: 'flag', render: (e, f) => {
                return <>{getFlag(f.country)}</>;
            }
        },
        // {
        //     title: <small>Country</small>, key: 'country', sorter: { compare: (a, b) => a.country.localeCompare(b.country) }, dataIndex: 'country', render: (e) => {
        //         return <>{e}</>;
        //     }
        // },
        {
            title: <div style={{ "textAlign": "left" }}><small>Suplr</small></div>, key: 'acct', dataIndex: 'acct', render: (e, f) => {
                // return <div style={{ "textAlign": "left" }}>{e}</div>

                return <Overlay width={600} component={<div style={{ "padding": "20px" }}><strong>Contact</strong><Divider dashed style={{ "margin": "10px 0px" }} /><div className="shim" /><div style={{ "margin": "-8px" }}><BusinessContactInfo id={f.id} /></div></div>}><a style={{ "textAlign": "left", "maxWidth": "250px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{e}</a></Overlay>;

            }
        },
        {
            title: <small>Pri</small>, className: "nowrap", key: 'priority', dataIndex: 'priority', render: (e, f) => {
                return e.substr(0,3).toUpperCase();
            }
        },
        {
            title: <small>Ord</small>, className: "nowrap", key: 'display_order', dataIndex: 'display_order', render: (e, f) => {
                return e
            }
        },

        {
            title: <small>O/H</small>, key: 'onhand', dataIndex: 'onhand', render: (e) => {

                return (e) ? <span style={{ "color": "#4F9818" }}>{e.toString()}</span> : ""

            }
        },
        {
            title: <small>{drawOOToolTip("O/O")}</small>, key: 'onorder', dataIndex: 'onorder', render: (e) => {
                return (e) ? <span style={{ "color": "#E77E21" }}>{drawOOMsg(e)}</span> : ""
            }
        },

        { title: <small><ColumnHelp title="Prices are in vender's currency" >Price</ColumnHelp> </small>, key: 'supplier_price', dataIndex: 'supplier_price' },
        { title: <small><ColumnHelp title="Discounts shown reflect general trade bookseller levels." >%</ColumnHelp> </small>, key: 'discount', dataIndex: 'discount' },
        { title: <small><ColumnHelp title={<>Returnability codes:<br /> Y = returnable <br />N = non-returnable <br />C = may be returnable, but conditions are applied. Contact vendor for details.</>} >Rtrn</ColumnHelp></small>, key: 'returnable', dataIndex: 'returnable' },
        { title: <small>As of</small>, key: 'as_of', width : 80, dataIndex: 'as_of', render: (e) => { return (e) ? <nobr>{moment(e * 1000).fromNow()}</nobr> : "" } },

       

    ]



    // "onhand": "7",
    // "onoorder": "",
    // "province": "BC",
    // "city": "Kelowna",
    // "store_acct": "1677004",
    // "store_name": "Mosaic Books",
    // "asof": 1625849949,
    // "price": "$32.95"



    // E77E21 4F9818

    // price,oh,oo,prov,name,city,asof 
    const store_columns = [



        // { title: <small>Account</small>, key: 'store_acct', dataIndex: 'store_acct' },

        {
            title: <div style={{ "textAlign": "left" }}><small>Name</small></div>, width: "200px", key: 'store_name', dataIndex: 'store_name', render: (e, f) => {
                return <Overlay width={500} component={<div style={{ "padding": "20px" }}><strong>Contact</strong><Divider dashed style={{ "margin": "10px 0px" }} /><div className="shim" /><div style={{ "margin": "-8px" }}><BusinessContactInfo id={f.id} /></div></div>}><a style={{ "textAlign": "left", "maxWidth": "250px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>{e}</a></Overlay>;
            },
            sorter: {
                compare: (a, b) => a.store_name.localeCompare(b.store_name)
            },
        },

        {
            title: <small>Price</small>, key: 'price', dataIndex: 'price',
            sorter: {
                compare: (a, b) => a.price > b.price,
            },

            render: (e) => {
                return (e === "not shared") ? "---" : e.replace("$", "");
            }
        },
        {
            title: <small>O/H</small>, width: "40px", key: 'onhand', dataIndex: 'onhand', render: (e) => {
                return (e) ? <span style={{ "color": "#4F9818" }}>{e.toString()}</span> : ""
            }
        },
        {
            title: <small>O/O</small>, width: "40px", key: 'onorder', dataIndex: 'onorder', render: (e) => {
                return (e) ? <span style={{ "color": "#E77E21" }}>{e.toString()}</span> : ""
            }
        },

        {
            title: <small>Prov</small>, key: 'province', dataIndex: 'province',

            sorter: {
                compare: (a, b) => a.province.localeCompare(b.province)
            }

        },

        {
            title: <div style={{ "textAlign": "left" }}><small>City</small></div>, key: 'city', width: "120px", dataIndex: 'city', render: (e) => {
                return <Tooltip title={<small>{e}</small>}><div style={{ "textAlign": "left", "maxWidth": "75px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }} >{e}</div></Tooltip>
            },
            sorter: {
                compare: (a, b) => a.city.localeCompare(b.city)
            }
        },


        { title: <small>As of</small>, key: 'as_of', dataIndex: 'as_of', render: (e) => { return (e) ? moment(e * 1000).fromNow() : "" } },

    ]


    const getFlag = (_country) => {

        let flag = "https://cdn1.bookmanager.com/i/CA.png"
        switch (_country) {

            case "Canada":
                flag = "CA"
                break;

            case "United Kingdom":
                flag = "CA"
                break;

            case "USA":
                flag = "US"
                break;

            default:
                flag = false
                break;

        }

        return (flag) ? <img src={"https://cdn1.bookmanager.com/i/" + flag + ".png"} /> : <></>


    }


    const [expanded, setExpanded] = useState([]);

    const expandCell = (_data) => {
        if (!expanded.includes(_data.key)) {
            setExpanded([_data.key]);
        } else {
            setExpanded([]);
        }
    }

    const addKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);;
            return item;
        }))
    }

    const drawChildTable = (_record) => {

        return (<BusinessContactInfo id={_record.id} />);
        return (<pre>{JSON.stringify(_record, null, 2)}</pre>)
        return (<small>[TODO] Add Store / Supplier Details </small>)
    }

    const [toggle, setToggle] = useState(false)


    const ftr = (_arr, _priority, _location) => {
        return _arr.filter(item => item.priority === _priority && item.country === _location).sort((a, b) => {
            return a.acct.localeCompare(b.acct);
        });
    }


    const countMatches = (arr1, arr2) => {
        let count = 0;
        for (let i = 0; i < arr1.length; i++) {
            if (arr2.includes(arr1[i])) {
                count++;
            }
        }
        return count;
    }


    const parseSuppliers = (_count = false, _toggle = toggle) => {



        



        let _s = [...suppliers];
        let _main = [];
        let _secondary = [];
        let _quickpicks = [];
        let _qps = session.quick_picks.reduce((a, { supplier }) => {
            if (supplier) a.push(supplier);
            return a;
        }, []);

        if (_qps.length > 0 && !_count) {
            _qps.forEach(item => {
                if (!_s.find(sup => sup.acct === item)) {
                    _quickpicks.push({ acct: item })
                } else {
                    _quickpicks.push(_s.find(sup => sup.acct === item))
                    _s = _s.filter(sup => sup.acct !== item);
                }
            })
        }


        if (window.sitesettings.is_canadian) {
            // Canadian order
            _main = ftr(_s, "Fav", "Canada").concat(ftr(_s, "Fav", "USA")).concat(ftr(_s, "Normal", "Canada")).concat(ftr(_s, "Normal", "USA"));
            _secondary = ftr(_s, "Private", "Canada").concat(ftr(_s, "Private", "USA"));
        } else {
            // American
            _main = ftr(_s, "Fav", "USA").concat(ftr(_s, "Fav", "Canada")).concat(ftr(_s, "Normal", "USA")).concat(ftr(_s, "Normal", "Canada"));
            _secondary = ftr(_s, "Private", "USA").concat(ftr(_s, "Private", "Canada"))
        }


        // Trevor asks not split private from fav,normal
        let _break = _main.length || null;
        _main = _main.concat(_secondary);
        _secondary = [];

        // Counts
        if (_qps.length > 0 && _count) {
            // only count suppliers that exist in both...
            let matches = countMatches(_main, _qps)
            return _main.length - matches;
        }

        if (_count) {
            return _secondary.length;
        }

        if (_qps.length > 0 && !_count && !toggle) {
            return _quickpicks;
        }

        let _ret = [];

        // Display logic (if added)
        if(_main.length > 0 && _main[0].display_order !== null){
            return(_quickpicks.concat(_main.sort((a, b) => a.display_order - b.display_order)))
        }


        // hack 
        if (_main.length > 0) {
            if (_break !== null) {
                _main[_break - 1]["break"] = true;
            }

        }


        _ret = _main;



        return _quickpicks.concat(_ret);
    }




    // Supl, oh, oo, sellpr, pm, SupPr, Disc, Cost, Asof, QP-Grp


    const groupMenu = (f) => {
        return (
            <div className="shadow" style={{ "maxHeight": "150px", "overflow": "auto", "boxShadow": "1px 1px 2px 2px #eee" }}>
                <Menu>
                    {groups.map(item => {

                        return (
                            <Menu.Item onClick={() => selectSupplier(f, false, false, item, true)} style={{ "padding": "0px 5px", "lineHeight": "16px", "height": "16px", "width": "40px" }} key={item}>
                                <small>{item}</small>
                            </Menu.Item>
                        )
                    })}
                </Menu>
            </div>
        )
    }


    const PublisherToolTip = (props) => {
        const { title = "" } = props;
        const [open, setOpen] = useState(true);
        return (
            <Tooltip onClick={(e) => {
                if (open) {
                    setOpen(false);
                }
            }} title={(open) ? title : ""} placement="bottom">
                {props.children}
            </Tooltip>
        )
    }

    const [open, setOpen] = useState(true);

    const order_columns = [
        {
            title: <Tooltip title={<small>FAV suppliers are shown first, followed by YES suppliers. When applicable, PRIVATE suppliers can be found under See More at the bottom of the list. NO suppliers will not be shown here, but can be found under the Pubstock tab. Changing supplier priority is done within the Bookmanager software.</small>}><small>Pref Supp</small></Tooltip>, key: 'flag', width: "30px", dataIndex: 'flag', render: (e, f) => {
                return (
                    <>
                        <Space size={3}>{(f.country && getFlag(f.country))}
                            <Tooltip placement="bottom" onClick={() => setOpen(false)} title={(!open) ? "" : <small>{f.title}  ({drawDeliveryTime(f.delivery_time, f.delivery_time_max)} days)<br />Click to create an order for this vendor under the default group O. Each click will add one copy on order.</small>}>
                                <a onClick={() => selectSupplier(f, false, true, "", true)} style={{ "fontSize": "11px", "fontWeight": (f.priority === "Fav") ? "bold" : "normal" }}>{f.acct}</a>
                            </Tooltip>
                        </Space>
                    </>
                );
            }
        },
        {
            title: <small>Grp</small>, key: 'inc', width: "inc", dataIndex: 'inc', render: (e, f) => {
                return (
                    <>
                        <Tooltip placement="top" title={<small>Create a new or additional order for this vendor using a group letter of your choosing</small>}>
                            <GroupPicker 
                                show_plus_circle={true}
                                value={e} 
                                onChange={(letter) => selectSupplier(f, false, false, letter, true)} />
                        </Tooltip>
                    </>
                )
            }
        },
        {
            title: <small>OH</small>, key: 'onhand', dataIndex: 'onhand', render: (e, f) => {

                if (!f.onhand && !f.onorder) {
                    return <span style={{ "color": "#f12a46" }}>no stock</span>
                } else {
                    return (e) ? e.toString() : ""
                }
            }

        },
        { title: <small>OO</small>, key: 'onorder', dataIndex: 'onorder', render: (e) => { return (e) ? drawOOMsg(e) : "" } },

        {
            title: <small>SellPr</small>, key: 'sell_price', dataIndex: 'sell_price', render: (e) => {
                return <a onClick={() => updatePrice(e)}>{e}</a>
            }
        },
        {
            title: <small>PM</small>, key: 'profit_margin', dataIndex: 'profit_margin', render: (e) => {
                return (e) ? <>{e}%</> : ""
            }
        },

        {
            title: <small><ColumnHelp title="Prices are in vender's currency" >SupPr</ColumnHelp> </small>, key: 'supplier_price', dataIndex: 'supplier_price', render: (e) => {
                return (<a onClick={() => updatePrice(e, true)}>{e}</a>)
            }
        },

        { title: <small><ColumnHelp title="Discounts shown reflect general trade bookseller levels." >Disc</ColumnHelp> </small>, key: 'discount', dataIndex: 'discount' },

        {
            title: <small>Exch</small>, key: 'exchange', dataIndex: 'exchange', render: (e) => {
                return (e != 1) ? <small>{e}</small> : ""
            }
        },
        { title: <small>Cost</small>, key: 'ex', dataIndex: 'cost' },
        { title: <small>As of</small>, key: 'as_of', dataIndex: 'as_of', render: (e) => { return (e) ? moment(e * 1000).fromNow() : "" } },
        {
            title: <small>QP-Grp</small>, width: "66px", key: 'quick', dataIndex: 'quick', render: (e, f) => {
                return <QuickPick supplier={f.acct} />
            }
        }
    ]

    const show_more = parseSuppliers(true);

    const drawFooter = () => {
        return (
            <div className="bc" style={{ "borderBottom": "1px solid", "textAlign": "left", "fontSize": "12px", "padding": "0px 8px" }}>
                <div style={{ "float": "right" }}>
                    <Space>
                        <conditional.true value={((prefs.last_used))}>
                            <div style={{ "float": "right" }}><a onClick={() => drawLast(prefs.last_used)}><small>{prefs.last_used} (Last used)</small></a><small> </small></div>
                        </conditional.true>
                        {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}

                        {(list_price_can !== "0.00" && <nobr><small><a onClick={() => updatePrice(list_price_can)}>${list_price_can} </a> CAD</small></nobr>)}
                        {(list_price_usa !== "0.00" && <nobr><small><a onClick={() => updatePrice(list_price_usa)}>${list_price_usa} </a> USD</small></nobr>)}
                    </Space>
                </div>
                <small>
                    <conditional.true value={(show_more && !wrap)}>
                        <a onClick={() => setToggle(!toggle)}>{(toggle) ? <>See less &nbsp;<CaretUpOutlined /></> : <Tooltip title={<small>Suppliers marked as PRIVATE will be hidden until See More is selected. When a supplier is marked as a Quick Pick, all other suppliers will appear under See More.</small>}>See {show_more} more &nbsp;<CaretDownOutlined /></Tooltip>}</a>
                    </conditional.true>
                    <conditional.true value={(!show_more)}>
                        &nbsp;
                    </conditional.true>
                    <div style={{ "height": (wrap) ? "19px" : "2px" }} className="shim" />
                </small>
            </div>
        )
    }

    const drawLast = (_supp) => {
        let _find = parseSuppliers().find(item => item.acct === _supp);
        if (_find) {
            selectSupplier(_find)
        } else {
            selectSupplier({ acct: _supp });
        }
    }

    const drawHeader = (_header, _memory = true) => {
        return (
            <div className="bc" style={{ "borderBottom": "1px solid", "textAlign": "left", "fontSize": "12px", "padding": "0px 8px", "height": "22px", "lineHeight": "20px" }}>
                <Space>
                    <strong className="c"><small>{_header}</small></strong>
                </Space>
            </div>
        )
    }

    if (is_order) {
        if (parseSuppliers().length === 0) {
            return (<></>);
        }
        return (<>
            <Table
                expandIcon={<></>}
                title={() => (wrap) ? drawHeader("Pubstock", true) : null}
                size="small"
                rowKey={"acct"}
                rowClassName={(record, index) => {
                    if (record.hasOwnProperty("break")) {
                        return "fav-break"
                    }

                }}
                className={(props.class ? "pub-table " + props.class : "pub-table")}
                columns={order_columns}
                dataSource={addKeys(parseSuppliers())}
                footer={() => drawFooter()}
                pagination={false}
                expandIconColumnIndex={-1}
                expandedRowKeys={expanded}
                onExpand={(e) => { return false; }}
                expandedRowRender={((record) => drawChildTable(record))}
            />
          
        </>)
    }

    return (<>
        <div style={{ "margin": "0 -20px" }} >
            {/* <Button style={{ "float": "right" }} onClick={() => drawComponent(null)} size="small" type="text" icon={<small><CloseOutlined style={{ "fontSize": "10px" }} /></small>} />
            <br clear="both" />
            <div style={{ "padding": "0px 20px", "marginTop": "-10px" }} >

                <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                    <div>Pubstock</div>
                </Typography.Title>

            </div> */}

            {/* <Layout style={{ "backgroundColor": "#fff" }}>
                <Layout.Content style={{ "borderLeft": "1px solid #eee", "padding": "0px" }} >
                    <div style={{ "padding": "30px" }}>
                        <Typography.Title level={4}>Pubstock</Typography.Title>
                        <Divider /> */}


            {/* <br clear="all" />
            <div className="shim" /><div className="shim" /> */}

            {/* <Typography.Title level={5}>{(showSuppliers) ? "Suppliers" : "Stores"}</Typography.Title> */}
            {/* <Divider /> */}
            <div>
                <Space size={0} align="start">
                    <conditional.true value={(!show_stores)}>
                        <div style={{ "margin": "0px 0px" }}>
                            <Table
                                expandable
                                // expandIcon={<></>} 
                                size="small"
                                pagination={false}
                                // title={() => drawHeader("Pubstock", false)}
                                // pagination={(suppliers.length > 10) ? true : false}
                                columns={suppliers_columns}
                                scroll={{ y: 320 }}

                                style={{ "width": "589px", "border": "1px solid", "borderLeft": "6px solid" }}
                                className={"mediumTable bc"}
                                // style={{ "border": "none", "borderBottom": "1px solid #eee", "borderTop": "1px solid #eee" }}
                                dataSource={suppliers}
                                rowKey={"acct"}
                                expandIconColumnIndex={-1}
                                expandedRowKeys={expanded}
                                onExpand={(e) => { return false; }}
                                expandIcon={() => { return (<></>) }}
                                expandedRowRender={((record) => drawChildTable(record))}
                            />
                        </div>
                    </conditional.true>
                    <conditional.true value={(show_stores)}>
                        <div style={{ "margin": "0px 0px" }}>
                            <Table
                                expandable
                                // expandIcon={<></>} 
                                size="small"
                                pagination={false}
                                scroll={{ y: 320 }}
                                // title={() => drawHeader("Stores", false)}
                                // pagination={(stores.length > 10) ? true : false}
                                columns={store_columns}
                                className={"mediumTable bc"}
                                style={{ "width": "589px", "border": "1px solid", "borderLeft": "6px solid" }}
                                dataSource={stores}
                                expandIconColumnIndex={-1}
                                expandedRowKeys={expanded}
                                onExpand={(e) => { return false; }}
                                expandIcon={() => { return (<></>) }}
                                expandedRowRender={((record) => drawChildTable(record))}
                            />
                        </div>
                    </conditional.true>
                </Space>
            </div>
        </div>
    </>
    )
}