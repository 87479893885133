import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { apiCall } from "../utils/Api";
import SuggestedCartsOnBrowse from "./catalogues/SuggestedCartsOnBrowse"
import Conditions from "./Conditions";
import Extras from "./Extras";
import ItemHistory4 from "./ItemHistory4";
import TestOrderBox from "./TestOrderBox";
import PreviousRender from "./title/PreviousRender";
import Title from "./title/Title";

// Layout for components based on view
// Add marker for where to draw subcomponents


export default function ItemLayouts(props) {

    const { 
        contextValues = [],
        data = false, 
        display, 
        index = 0, 
        analytics_title = false,
        cart_update = false,
        isbn = "", 
        actual_id = "",
        drawComponent = () => { },
    } = props;

    const [highlight, setHighlight] = useState(data.highlight)

    const [dataSet, setDataSet] = useState(data);

    // if there is no data, fetch it. 
    const loadData = () => {
        if (dataSet) {
            return;
        }
        
        let _args = {
            isbn : isbn,
        }

    
        if(actual_id){   
            _args.titlelist_def_id = actual_id; 
        }

        let api_path = (analytics_title) ? "reports/analytics/getTitleOrBMTitle" : "title/getItem";
        apiCall(api_path, _args, (_status, _result) => {
            if (_status) {
                if(Array.isArray(_result)){
                    setDataSet(false);
                } else {
                    setDataSet(_result);
                }
            }
        })
    }
    useEffect(loadData, [])

    if (!dataSet) {
        return (<Spin spinning style={{ "height": "320px" }}>&nbsp;</Spin>);
    }


    function drawBorder(){
        return {
            borderStyle: "solid",
            borderColor: "#ff0099",
            borderWidth: "3px",
            marginTop: "0px",
            backgroundColor: "#ff0099",
        }
    }

    function topRowSequenceNum(){
        let text = ""
        if(highlight){
            text = "#" + data.sequence_num + " - Highlighted";
            return (
                <Divider style={drawBorder()} orientation="left" plain><small className="c" style={{ "fontWeight": "400", "color": "#FFFFFF", "fontSize": "16px" }}>{text}</small></Divider> 
            )
        }
        text = "#" + data.sequence_num;
        return (
            <Divider style={{"marginTop": "0px"}} orientation="left" plain><small className="c" style={{ "fontWeight": "400", "fontSize": "16px" }}>{text}</small></Divider>
        )
    }

    //checks to see if the Suggested Cart section should be displayed
    function checkForSuggestionsDisplay(){
        if(dataSet.suggested_cart_info){
            return true;
        }
        return false;
    }

    // eslint-disable-next-line default-case
    switch (display) {
        case "single":
            return (<div>
                <div style={{ "maxWidth": "570px" }}><Title actual_id={actual_id} drawComponent={drawComponent} isbn={(dataSet) ? data.isbn : isbn} data={(dataSet) ? dataSet : {}} display="summary" /></div>
                <Conditions conditions={dataSet.conditions} data={dataSet} isbn={(dataSet) ? dataSet.isbn : isbn} />
                {/* <Extras data={(dataSet) ? dataSet : {}} isbn={(dataSet) ? dataSet.isbn : isbn} /> */}
            </div>)
            break;
        case "calendar":
            return (
                <div key={(dataSet) ? dataSet.isbn : isbn}>
                    <Row gutter={18}>
                        <Col flex={"auto"}>
                            <div style={{ "maxWidth": "570px" }}><Title actual_id={actual_id} drawComponent={drawComponent} isbn={(dataSet) ? data.isbn : isbn} data={(dataSet) ? dataSet : {}} display="summary" /></div>
                            <Conditions conditions={dataSet.conditions} data={dataSet} isbn={(dataSet) ? dataSet.isbn : isbn} />
                            <Extras data={(dataSet) ? dataSet : {}} isbn={(dataSet) ? dataSet.isbn : isbn} />
                        </Col>
                        <Col style={{"maxWidth" : "608px"}} flex={"608px"}>
                            <PreviousRender isbn={(dataSet) ? dataSet.isbn : isbn} />
                        </Col>
                    </Row>
                </div>
            )
            break;
        case "cards":
            return (
                // five at a time...
                <div key={dataSet[0].isbn}>
                    <div className="cardgrid">
                        {dataSet.map((item, index) => {
                            return (<div key={dataSet.isbn}><Title actual_id={actual_id} cart_update={cart_update} drawComponent={drawComponent} data={item} display="card" /></div>)
                        })}
                    </div>
                    <div className="shim" /><div className="shim" />
                </div>)
            break;
            case "details2":
                return (
                    <div key={(dataSet) ? dataSet.isbn : isbn}>
                        {highlight != null && topRowSequenceNum()}
                        <Row gutter={18}>
                            <Col flex={"auto"}>
                                <div style={{ "maxWidth": "570px" }}>
                                    <Title
                                        analytics_title={analytics_title}
                                        actual_id={actual_id}
                                        drawComponent={drawComponent} 
                                        isbn={(dataSet) ? data.isbn : isbn} 
                                        data={(dataSet) ? dataSet : {}} 
                                        display="summary"
                                        highlight={highlight}
                                        setHighlight={setHighlight}
                                    />
                                </div>
                            </Col>
                            <Col style={{"maxWidth": "608px"}} flex={"608px"}>
                                <Conditions conditions={dataSet.sales ? dataSet.sales : dataSet.conditions} data={dataSet} isbn={(dataSet) ? dataSet.isbn : isbn} />
                                {(checkForSuggestionsDisplay() &&<SuggestedCartsOnBrowse data={(dataSet) ? dataSet : {}} isbn={(dataSet) ? dataSet.isbn : isbn} contextValues={contextValues}/>)}
                                <Extras cart_update={cart_update} data={(dataSet) ? dataSet : {}} isbn={(dataSet) ? dataSet.isbn : isbn} />
                            </Col>
                        </Row>
                    </div>
                )
            break;
        case "details":
            return (
                <div key={(dataSet) ? dataSet.isbn : isbn}>
                    <Row gutter={18}>
                        <Col span={12}>
                            <Title actual_id={actual_id} drawComponent={drawComponent} isbn={(dataSet) ? "" : isbn} data={(dataSet) ? dataSet : {}} display="summary" />
                        </Col>
                        <Col span={12}>
                            <ItemHistory4 drawComponent={drawComponent} data={dataSet} isbn={(dataSet) ? data.isbn : isbn} />
                            <div className="shim" /><div className="shim" />
                            <TestOrderBox data={(dataSet) ? dataSet : {}} isbn={(dataSet) ? dataSet.isbn : isbn} />
                        </Col>
                    </Row>
                </div>
            )
            break;
        case "previous":
        case "next":
            return (
                <div key={dataSet.isbn}>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Title actual_id={actual_id} drawComponent={drawComponent} data={dataSet} display="summary" />
                            <div className="shim" /><div className="shim" /><div className="shim" />
                            <ItemHistory4 drawComponent={drawComponent} isbn={dataSet.isbn} />
                        </Col>
                        <Col span={12}>
                            {/* <RelatedTitle drawComponent={drawComponent} display="previous" data={data} /> */}
                        </Col>
                    </Row>
                    <Divider dashed />
                </div>
            )
        break;
    }
    return (<>Item Renderer</>);
}