import React from "react";
import { addCommas, interpolateColor } from "../../utils/Utils";
import { Col, Row, Space, Tooltip } from "antd";

/**
 * Onhand Component
 */
export default function OnHand(props) {
    const {
        filters = {},
        list = true,
        scale = false,
        onhand = [],
        salesrtrn = [],
        record = {},
        getSet = () => { },
        setDrilldown = () => { },
        showOnOrder = false,
    } = props;

    const { show_which = "dollars" } = filters;

    const rel_scale = (scale) ? 1 : getSet(record, "rel_scale");

    // Generate color gradients for ohhand data
    const onhand_colours = interpolateColor("#facbb4", "#e78c41", onhand.length);

    function pol(number) {
        return number >= 0 ? `+${number}` : `${number}`;
    }

    /**
     * Generates tooltip content for a given item.
     */

    const drawToolTip = (item, per = "") => {
        const dollars = getSet(record, item.key, false, "dollars")
        const copies = getSet(record, item.key, false, "unit_copies")
        const isbns = getSet(record, item.key, false, "unique_isbns");
        // this is ugly...
        const seg_turns = getSet(record, item.key.replace("onhand", "turns"), false, show_which);
        const turns = getSet(record, "turns", false, show_which);

        let dollars_per = 0;

        if (per) {
            const dollars_past = getSet(record, per, true, "dollars");
            dollars_per = dollars / dollars_past * 100;


        }

        let title_label = item.label;
        let label_1 = "retail";
        let val_1 = "$" + addCommas(dollars);

        let label_2 = "copies";
        let val_2 = addCommas(copies);

        let label_3 = "ISBNs";
        let val_3 = addCommas(isbns);



        switch (item.key) {

            case "onorder_total":
                return (
                    <div>
                        <div>{title_label}</div>
                        <Space>
                            <div>
                                <div>{val_1}</div>
                                <div>{val_2}</div>
                                <div>{val_3}</div>
                            </div>
                            <div>
                                <div>cost</div>
                                <div>{label_2}</div>
                                <div>{label_3}</div>
                            </div>
                        </Space>
                    </div>
                )

                break;
            case "onhand_days":
                return (
                    <div>
                        <div>{title_label}</div>
                        <Space>
                            <div>
                                <div>${addCommas(dollars)}</div>
                                <div>{addCommas(copies)}</div>
                                <div>{turns}</div>
                            </div>
                            <div>
                                <div>cost</div>
                                <div>copies</div>
                                <div>turns</div>
                            </div>
                        </Space>
                    </div>
                )
                break;
            case "onhand_diff":
                return (
                    <div>
                        <div>{title_label}</div>
                        <div>{pol(dollars_per.toFixed(1))}% (${addCommas(dollars)})</div>
                        <Space>
                            <div>
                                <div>{addCommas(pol(copies))}</div>
                            </div>
                            <div>
                                <div>copies</div>
                            </div>
                        </Space>
                    </div>
                )

                break;

            case "onhand_0":
            case "onhand_1":
            case "onhand_2":
            case "onhand_3":
            case "onhand_4":
            case "onhand_5":
            case "onhand_6":
                return (
                    <div>
                        <div>{title_label}</div>
                        <Space>
                            <div>
                                <div>${addCommas(dollars)}</div>
                                <div>{addCommas(copies)}</div>
                                <div>{addCommas(isbns)}</div>
                                <div>{seg_turns}</div>
                            </div>
                            <div>
                                <div>cost</div>
                                <div>copies</div>
                                <div>ISBNs</div>
                                <div>turns</div>
                            </div>
                        </Space>
                    </div>
                )
                break;
        }

        return (
            <div>
                <div>{title_label}</div>
                <Space>
                    <div>
                        <div>{val_1}</div>
                        <div>{val_2}</div>
                        <div>{val_3}</div>
                    </div>
                    <div>
                        <div>{label_1}</div>
                        <div>{label_2}</div>
                        <div>{label_3}</div>
                    </div>
                </Space>
            </div>
        );
    };


    // const drawToolTip = (item, per) => {
    //     const title = item?.label || "";
    //     const dollars = addCommas(getSet(record, item.key, false, "dollars"));
    //     const copies = addCommas(getSet(record, item.key, false, "unit_copies"));
    //     const isbns = addCommas(getSet(record, item.key, false, "unique_isbns"));

    //     let dollars_per;

    //     if (per) {
    //         const dollars_past = addCommas(getSet(record, per, false, "dollars"));
    //         dollars_per = parseInt(dollars.replace(",", ""))/parseInt(dollars_past.replace(",", "")) * 100;
    //     }


    //     return (
    //         <div>
    //             <div>{title}</div>

    //             <Space>
    //                 <div>
    //                     <div>${dollars} {(per && <em style={{ "opacity": "0.7" }}>({dollars_per.toFixed(1) + "%"})</em>)}</div>
    //                     <div>{copies}</div>
    //                     <div>{isbns}</div>
    //                 </div>
    //                 <div>
    //                     <div>&nbsp;</div>
    //                     <div>Copies</div>
    //                     <div>ISBNs</div>
    //                 </div>
    //             </Space>
    //         </div>
    //     );
    // };

    /**
     * Renders a segment with optional nested segment inside.
     */
    const drawSegment = (item, _color = "#ff0000", inside = false, _rtn_color) => {
        const scale = getSet(record, item.key + "_w");
        const width_percent = (scale * rel_scale) * 100;
        const multiplier = 100 / width_percent;

        const iscale = inside ? getSet(record, inside.key + "_w") : 0;
        const inside_percent = inside ? ((iscale * rel_scale) * 100) * multiplier : 0;

        return (
            <div
                className="lighten"
                style={{
                    width: list ? `${width_percent}%` : "10px",
                    height: list ? "10px" : `${width_percent}%`,
                    backgroundColor: _color,
                    position: "relative"
                }}
            >
                {inside && (
                    <Tooltip title={drawToolTip(inside)}>
                        <div
                            onClick={() => setDrilldown(inside.key, record?.id)}
                            className="txtptrn"
                            style={{
                                width: list ? `${inside_percent}%` : "10px",
                                height: list ? "10px" : `${inside_percent}%`,
                                backgroundColor: _rtn_color,
                                position: "absolute",
                                right: "0px",
                                zIndex: "10"
                            }}
                        />
                    </Tooltip>
                )}
                <Tooltip title={drawToolTip(item)}>
                    <div
                        onClick={() => setDrilldown(item.key, record?.id)}
                        style={{
                            width: list ? "100%" : "10px",
                            height: list ? "10px" : "100%"
                        }}
                    />
                </Tooltip>
            </div>
        );
    };

    /**
     * Renders all onhand segments.
     */
    const drawSegments = () => {
        return onhand.map((item, index) => {
            return drawSegment(item, "#" + item.color);
        });
    };

    /**
     * Renders the onhand difference indicator.
     */
    const drawOnhandDiff = (show = true) => {
        const is_positive = getSet(record, "onhand_diff") > 0;
        const onhand_per = getSet(record, "onhand_total_w");
        const scale = getSet(record, "onhand_diff_w");
        const width_percent = (scale * rel_scale) * 100;

        const size = list ? 998 * (width_percent / 100) : 400 * (width_percent / 100);

        if (is_positive) {
            if (!show) return null;

            return (
                <div
                    style={{
                        position: "relative",
                        width: "0px",
                        height: "0px",
                        top: list ? "-5px" : "0px",
                        right: list ? `${size}px` : "5px",
                        zIndex: "10"
                    }}
                >
                    <Tooltip title={drawToolTip({ key: "onhand_diff", label: "Average Onhand YOY change" }, "onhand_days")}>
                        <div
                            onClick={() => setDrilldown("onhand_diff", record.id)}
                            className="lighten"
                            style={{
                                width: list ? `${size}px` : "5px",
                                height: list ? "5px" : `${size}px`,
                                backgroundColor: "#0f0"
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (!show) {
            return (
                <div
                    style={{
                        opacity: "0",
                        width: list ? `${width_percent}%` : "6px",
                        height: list ? "6px" : `${width_percent}%`,
                        pointerEvents: "none"
                    }}
                />
            );
        }




        return (
            <Tooltip title={drawToolTip({ key: "onhand_diff", label: "Average Onhand YOY change" }, "onhand_days")}>
                <div
                    className="lighten"
                    onClick={() => setDrilldown("onhand_diff", record.id)}
                    style={{
                        backgroundColor: "#d03333",
                        width: list ? `${width_percent}%` : "5px",
                        height: list ? "5px" : `${width_percent}%`,
                        marginBottom: list ? "-0px" : "0",
                        marginRight: list ? "0" : "-0px"
                    }}
                />
            </Tooltip>
        );
    };



    /**
    * Renders the onhand difference indicator.
    */
    const drawOnOrder = (show = true) => {

        // hide if received is unchecked
        if(showOnOrder){
            return(<></>);
        }

        const scale = getSet(record, "onorder_total_w");
        const width_percent = (scale * rel_scale) * 100;
        // {drawSegment({ key: "onorder_total", label: "Onorder Total" }, "#ff9900")}
        const size = list ? 998 * (width_percent / 100) : 400 * (width_percent / 100);



        if (!show) {
            return (
                <div
                    style={{
                        opacity: "0",
                        width: list ? `${width_percent}%` : "10px",
                        height: list ? "10px" : `${width_percent}%`,
                        pointerEvents: "none"
                    }}
                />
            );
        }




        return (
            <Tooltip title={drawToolTip({ key: "onorder_total", label: "Onorder" })}>
                <div
                    onClick={() => setDrilldown("onorder_total", record?.id)}
                    className="lighten"
                    style={{
                        backgroundColor: "#ff9900",
                        width: list ? `${width_percent}%` : "10px",
                        height: list ? "10px" : `${width_percent}%`,
                        marginBottom: list ? "-10px" : "0",
                        marginRight: list ? "0" : "-10px"
                    }}
                />
            </Tooltip>
        );
    };



    const drawLabel = () => {

        let ret = "";
        switch (show_which) {
            case "dollars":
                ret = <>${addCommas(getSet(record, "onhand_total", false, "dollars"))} <small>cost</small></>
                break;
            case "unit_copies":
                ret = <>{addCommas(getSet(record, "onhand_total", false, "unit_copies"))} <small>units</small></>
                break;
            case "unique_isbns":
                ret = <>{addCommas(getSet(record, "onhand_total", false, "unique_isbns"))} <small>isbns</small></>
                break;
        }

        return ret;

    }

    return (
        <div style={{ "minWidth": "20px" }}>

            <Row>
                {(list &&
                    <Col flex={"200px"}>
                        <div style={{ "padding": "0px 0px" }}>
                            <Space>
                                <div style={{ "width": "70px" }}>Onhand</div>
                                <div style={{ "width": "100px", "textAlign": "right" }}>{drawLabel()}</div>
                            </Space>
                        </div>
                    </Col>
                )}

                <Col flex={"auto"}>
                    <div>
                        <div style={{ display: list ? "block" : "flex" }}>
                            {/* Overall onhand bar */}
                            <div
                                style={{
                                    height: list ? "10px" : "400px",
                                    display: "flex",
                                    flexDirection: list ? "row" : "column-reverse",
                                    alignItems: "flex-end"
                                }}
                            >

                                {drawSegment({ key: (show_which === "dollars") ? "onhand_days" : "onhand_total", label: "Average Onhand" }, "#c25135")}
                                {drawOnhandDiff()}


                            </div>

                            {/* Individual onhand and returns segments */}

                            <div
                                style={{
                                    height: list ? "10px" : "400px",
                                    display: "flex",
                                    flexDirection: list ? "row" : "column-reverse"
                                }}
                            >
                                {drawSegments()}
                                {drawOnOrder()}
                                {drawOnhandDiff(false)}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    );
}
